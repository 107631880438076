export const formatNewUserTaskStatus = (newUserTaskResp) => {
  const textMap = {
    NO_FIRST_WEEK_TASK: '无首周任务',
    COMPLETED: '已完成首周分享任务',
    PENDING: '待首周分享',
    EXPIRED: '首周分享已失效'
  };

  const formatCountdown = (seconds) => {
    if (seconds <= 0) return '';
    const days = Math.floor(seconds / (24 * 3600));
    const hours = Math.floor((seconds % (24 * 3600)) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${days}天${hours}小时${minutes}分`;
  };

  const text = textMap[newUserTaskResp?.newUserTaskStatus];

  // 如果是PENDING状态，添加倒计时
  if (newUserTaskResp?.newUserTaskStatus === 'PENDING') {
    const countdownText = formatCountdown(newUserTaskResp?.remainingExpiredSecondsTimeThisWeek);
    return `${text}${countdownText ? ` (${countdownText})` : ''}`;
  }

  return text || '-';
}; 