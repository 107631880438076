<template>
  <div :class="subDetailType">
    <div class="task-form">
      <p class="form-name">
        <a-tooltip
          :overlayStyle="{ maxWidth: '500px' }"
          v-if="['TASK_FIRST_PASS', 'TASK_FIRST_FOLLOW_UP'].includes(detailType)"
        >
          <template slot="title">
            <div class="task-explainTitleTC">
              <span class="task-titleTxt">任务说明</span
              ><span
                class="task-explain"
                @click="lookWord"
                v-if="['TASK_FIRST_PASS', 'TASK_FIRST_FOLLOW_UP'].includes(detailType)"
                >查看文档说明</span
              >
            </div>

            <div v-if="['TASK_FIRST_PASS', 'TASK_FIRST_FOLLOW_UP'].includes(detailType)">
              【完成方式】电话/文字<br />
              【完成时效】72小时<br />
              【完成注意事项】<br />
              ①请务必按照跟进记录提示，如实记录家长反馈内容<br />
              ②若和家长沟通失败或未排课，需正确选择原因和下次跟进时间，并在下次跟进的任务时效内完成新生首通<br />
              ③若客户不愿电话沟通：时效内依据跟进记录提示通过文字完成对应沟通/动作，注意不要遗漏沟通要点的确
            </div>
          </template>
          <i class="explainTC" v-if="['TASK_FIRST_PASS', 'TASK_FIRST_FOLLOW_UP'].includes(detailType)"></i>
        </a-tooltip>
        {{ formInfoData.question }}
        <span class="pending-status" v-if="!formInfoData.finished">待处理</span>
        <span class="success-status" v-if="formInfoData.finished && !formInfoData.deleted">已完成</span>
        <span class="refuse-status" v-if="formInfoData.finished && formInfoData.deleted">已拒绝</span>
      </p>
      <div class="form-teacher">
        <img :src="defaultImg" alt="" />
        <span
          >{{ formInfoData.startUserName ? formInfoData.startUserName : '系统'
          }}<span v-if="formInfoData.userRole">（ {{ formInfoData.userRole }} ）</span></span
        >
        提交于
        <span>{{ $moment(formInfoData.startTime).utcOffset(8).format('YYYY年MM月DD日 HH:mm') }}</span>
        | 最迟解决时间

        <span :class="formInfoData.expireFlag ? 'dueDate' : ''">{{ formInfoData.dueDate }}</span>
      </div>
      <div class="form-des" v-if="formInfoData.profileInfo">
        <p v-if="formInfoData.profileInfo.trailScheduleDetail !== null">以下课节为该学员体验课课节，可查看课节回放；</p>
        <p v-else>该学员本科目暂无体验课信息</p>
      </div>
      <div class="form-des" v-if="formInfoData.description">
        <p>{{ formInfoData.description }}</p>
      </div>

      <div class="form-imgs" v-if="formInfoData.imgs">
        <img v-for="item in formInfoData.imgs.split(',')" :src="item" preview="1" :key="item" alt="" />
      </div>
      <div
        class="experienceClass"
        v-if="formInfoData.profileInfo && formInfoData.profileInfo.trailScheduleDetail !== null"
      >
        <div>
          <div
            class="playCourse"
            @click="playCourse(formInfoData.courseInfo.platform)"
            v-if="formInfoData.courseInfo && formInfoData.courseInfo.scheduleStatus === 'COMPLETED'"
          >
            回放
          </div>
          <div
            class="playCourse"
            @click="playClass(formInfoData.courseInfo.platform)"
            v-if="formInfoData.courseInfo && formInfoData.courseInfo.scheduleStatus === 'NORMAL'"
          >
            监课
          </div>

          <div class="experience-name">{{ formInfoData.profileInfo.trailScheduleDetail.name }}</div>

          <div class="course-type">
            <span
              >上课平台：{{
                initOptionsType(formInfoData.profileInfo.trailScheduleDetail.platform, platformsArr)
              }}</span
            >
          </div>
          <div class="course-type">
            <span
              >开课时间：{{ formInfoData.profileInfo.trailScheduleDetail.startTime }} -
              {{ formInfoData.profileInfo.trailScheduleDetail.endTime.substring(11) }}
            </span>
          </div>
          <div class="course-type">
            <span>授课老师：{{ formInfoData.profileInfo.trailScheduleDetail.teacherName }}</span>
          </div>
          <div class="course-type">
            <span>课后点评：{{ formInfoData.profileInfo.trailScheduleDetail.comments }}</span>
          </div>
        </div>
      </div>
      <div class="experienceClass">
        <div>
          <div style="display: flex; justify-content: space-between">
            <div class="experience-name">选课信息</div>
            <div v-if="formInfoData.upComingTypeValues === 'UpComing'">
              <a-button
                type="primary"
                style="margin-left: 10px"
                ghost
                size="small"
                @click="toScheduleClasses"
                v-if="formInfoData.showScheduleButton"
              >
                去排课
              </a-button>
            </div>
          </div>
          <div class="course-type" v-if="!formInfoData.selfBookingInfo">
            <span>未完成选课</span>
          </div>
          <template v-else>
            <div class="course-type">
              <span>
                班级名称：<span class="class-name" @click="toClassDetail">{{ formClassName || '-' }}</span>
              </span>
            </div>
            <div class="course-type">
              <span>开课时间：{{ firstCourseScheduleStartTime || '-' }}</span>
            </div>
            <div class="course-type">
              <span>课频：{{ frequencyPerWeek ? `一周${frequencyPerWeek}次` : '-' }} </span>
            </div>
          </template>
        </div>
      </div>

      <div class="form-des">
        <span>新生首周分享任务：</span>
        <span>{{ newUserTaskStatusText }}</span>
      </div>
    </div>
    <RelatedStudents :formInfoData="formInfoData" @onGetStudentId="onGetStudentId" />

    <emailDetail
      v-if="showEmailDetail"
      @onCancel="cancelEmailDetail"
      :recordData="recordEmailData"
      :roleType="roleType"
    ></emailDetail>
  </div>
</template>

<script>
import emailDetail from '@/views/mainHome/emailDetail.vue';
import defaultImg from '../assets/service_pic_head.png';
import RelatedStudents from './RelatedStudents.vue';
import { formatNewUserTaskStatus } from '@/utils/newUserTask';

export default {
  props: {
    formInfoData: {
      type: Object,
    },

    subDetailType: {
      // 区分是老师还是班主任，班主任主题色是绿色
      type: String,
      default: 'teacher',
    },
    detailType: {
      type: String,
      default: '',
    },
  },
  components: {
    emailDetail,
    RelatedStudents,
  },

  data() {
    return {
      loading: false,
      platformsArr: [
        { label: 'Classin', value: 'CLASS_IN' },
        { label: '悟空教室', value: 'TALK_CLOUD' },
      ],
      roleType: 'CLASS_ADMIN',
      recordEmailData: {},
      showEmailDetail: false,
      cursor: 'cursor',
      defaultImg,
      pictures: [],
      subjectArr: [
        { label: '中文', value: 'CHINESE' },
        { label: '数学', value: 'MATH' },
        { label: '英文', value: 'ENGLISH' },
      ],
      newUserTaskResp: null,
    };
  },
  computed: {
    formClassId() {
      return this.formInfoData?.selfBookingInfo?.data?.formClassId;
    },
    formClassName() {
      return this.formInfoData?.selfBookingInfo?.data?.formClass?.className;
    },
    frequencyPerWeek() {
      return this.formInfoData?.selfBookingInfo?.data?.frequencyPerWeek;
    },
    firstCourseScheduleStartTime() {
      return this.formInfoData?.selfBookingInfo?.data?.firstStartDateTime;
    },
    newUserTaskStatusText() {
      return formatNewUserTaskStatus(this.formInfoData.newUserTaskResp);
    },
  },

  methods: {
    toClassDetail() {
      const routeUrl = this.$router.resolve({
        path: '/workoutSchedule/classDetail',
        query: { classId: this.formClassId },
      });

      window.open(routeUrl.href, '_blank');
    },
    lookDetailEmail() {
      this.showEmailDetail = true;
      this.recordEmailData = this.formInfoData?.profileInfo?.admissionProfile?.stuFollowRecordDto;
    },
    cancelEmailDetail() {
      this.showEmailDetail = false;
    },
    initOptionsType(value, typeArr) {
      let name = '';
      typeArr?.forEach?.((item) => {
        if (item.value === value) {
          name = item.label;
        }
      });
      return name;
    },

    toScheduleClasses() {
      this.$emit('ToScheduleClasses');
    },
    // 复制链接
    copyLink() {
      const url = `${process.env.VUE_APP_PROFILE_URL}?orderNum=${this.formInfoData?.profileInfo?.admissionProfile?.orderNum}&source=CA_STUDIO`;
      const input = document.createElement('input');
      input.value = url;
      document.body.appendChild(input);
      input.select();
      document.execCommand('Copy');
      document.body.removeChild(input);
      this.$message.success('已复制链接');
    },
    hrefStudyPlan() {
      const obj = this.formInfoData.studentArray[0];
      const routeUrl = this.$router.resolve({
        path: '/learningPlanning',
        query: { studentObj: JSON.stringify(obj) },
      });
      window.open(routeUrl.href, '_blank');
    },
    lookWord() {
      let linkUrl = '';
      // 新生首通
      if (['TASK_FIRST_PASS', 'TASK_FIRST_FOLLOW_UP'].includes(this.detailType)) {
        linkUrl = 'https://wukongedu.feishu.cn/wiki/wikcnGGWcgRLcjvZIrsRZlHo90c';
      }
      window.open(linkUrl, '_blank');
    },
    onGetStudentId(data) {
      this.$emit('onGetStudentId', { name: data.name, id: data.studentId });
    },
    onPictures(datas) {
      this.$emit('onPictures', datas);
    },
    playCourse(platform) {
      this.$emit('playCourse', platform);
    },
    playClass(platform) {
      this.$emit('playClass', platform);
    },
  },
};
</script>

<style lang="less" scoped>
.class-name {
  color: #43d186;
  cursor: pointer;
}

.cursor {
  cursor: pointer;
  color: #009cff !important;
}
.classAdmin {
  .cursor {
    cursor: pointer;
    color: #43d186 !important;
  }

  .title::before {
    background-color: #43d186;
  }
}
.title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 20px;
}
.title::before {
  content: '';
  display: block;
  float: left;
  margin: 4px 12px 0 0;
  width: 4px;
  height: 20px;
  background-color: #009cff;
  border-radius: 0px 6px 6px 0px;
}
.task-form {
  border-bottom: 1px solid #eee;
}

.form-name span {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 2px 10px;
  border-radius: 6px;
  margin: 0 10px;
}

.pending-status {
  color: #ffbf00;
  background-color: rgba(255, 191, 0, 0.09);
}
.success-status {
  color: #00cf64;
  background-color: rgba(0, 207, 100, 0.09);
}
.refuse-status {
  color: #ff5353;
  background-color: rgba(255, 83, 83, 0.09);
}
.form-teacher {
  font-size: 16px;
  font-weight: 400;
  color: #999;
  line-height: 22px;
  margin-bottom: 15px;
}
.form-teacher .dueDate {
  color: #ff5454;
}
.form-teacher span {
  color: #333;
}
.form-teacher img {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 10px;
}
.form-des {
  font-size: 15px;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  margin-bottom: 15px;
}
.form-imgs {
  margin-bottom: 20px;
}
.form-imgs img {
  width: 80px;
  height: 80px;
  border-radius: 4px;
  margin-right: 10px;
}

.courseInfo {
  padding: 20px 0;
}
.experienceClass {
  padding: 20px 16px;
  background: #43d18614;
  border-radius: 20px;
  position: relative;
  margin-bottom: 15px;
}
.course-info {
  padding: 20px 16px;
  background: linear-gradient(180deg, #ecf7ff 0%, #f7fcff 100%);
  border-radius: 20px;
  position: relative;
}
.course-name {
  font-size: 16px;
  font-weight: 600;
  color: #009cff;
  line-height: 22px;
  margin-bottom: 10px;
}
.experience-name {
  font-size: 16px;
  font-weight: 600;
  color: #43d186;
  line-height: 22px;
  margin-bottom: 10px;
}
.course-info a:hover {
  text-decoration: underline;
}
.course-type {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 18px;
  margin-top: 10px;
}

.student-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.student-item p {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.student-item img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 7px;
}
.student-item .student-name {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  line-height: 22px;
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.student-wrap {
  display: flex;
  margin-bottom: 22px;
  justify-content: space-between;
}
.playCourse {
  background-color: #fff;
  color: #43d186;
  padding: 4px 8px;
  border-radius: 10px;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 10px;
}
.explainTC {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('../assets/explain.png') no-repeat center;
  background-size: 100%;
  cursor: pointer;
  margin-right: 5px;
}
.task-explainTitleTC {
  padding-bottom: 5px;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.task-titleTxt {
  font-size: 18px;
  font-weight: 600;
}
.task-explain {
  color: #2eaaf9;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}
.form-name {
  font-size: 20px;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
</style>
