<template>
  <div class="concat-con">
    <div class="concat-item" style="display: flex; justify-content: space-between">
      <div style="flex: 1">
        <span>班级名称：</span>
        <span style="cursor: pointer; color: #04cb94" @click="adjustFun">{{ classItem.className }}</span>
      </div>
      <div
        v-if="adjustScheduleProcess !== 'ALL' && upComingTypeValues === 'UpComing' && showOperate"
        style="width: 170px"
      >
        <a-button size="small" type="primary" @click="matchClass">{{
          substitute ? '匹配代课' : '匹配新老师'
        }}</a-button>
        <a-button size="small" type="primary" style="margin-left: 10px" @click="adjustFun">调整课节</a-button>
      </div>
    </div>
    <div class="concat-item bot-border">
      <span class="concat-label">{{ courseTypeMap[classItem.courseType] || classItem.courseType }}</span>
      <span class="concat-label">{{ classTypeMap[classItem.classType] || classItem.classType }} </span>
      <p v-if="classItem.studentsArray.length > 1 || classItem.studentsArray.length === 0">
        <span class="concat-label">学生：{{ classItem.studentsArray.length }}人 </span>
        <span class="openClassbtn" v-if="classItem.studentsArray.length !== 0" @click="onUnfoldStudents">{{
          studnetTxt
        }}</span>
      </p>
      <span
        class="concat-label studentHover"
        v-else-if="classItem.studentsArray.length === 1"
        @click="onGetStudentId(classItem.studentsArray[0])"
        >学生：<span>
          {{
            classItem.studentsArray[0].fullName ? classItem.studentsArray[0].fullName : classItem.studentsArray[0].name
          }}{{ classItem.studentsArray[0].fullName ? '' : '(' + classItem.studentsArray[0].code + ')' }}></span
        >
      </span>
      <div class="class-wrap" v-if="visibleStudents">
        <div
          class="class-item studentHover"
          v-for="item in classItem.studentsArray"
          :key="item.id"
          @click="onGetStudentId(item)"
        >
          <span v-if="item.fullName">{{ item.fullName }}</span>
          <span v-else>{{ item.name }}({{ item.code }})></span>
        </div>
      </div>
    </div>
    <div class="concat-item scheduleCls" v-if="classItem.scheduleArray && classItem.scheduleArray.length">
      <span>相关课节：{{ classItem.scheduleArray.length }}</span>
      <span class="openClassbtn" @click="toggleClass">{{ toggleTxt }}</span>
    </div>
    <div class="class-wrap" v-show="visibleClass">
      <div class="class-item" v-for="(subClassItem, index1) in classItem.scheduleArray || []" :key="index1">
        <p v-if="subClassItem.courseSectionRespDTO" style="display: flex">
          <!-- {{
            `${
              subClassItem.courseSectionRespDTO.data.level3
                ? '第' + subClassItem.courseSectionRespDTO.data.level3 + '课'
                : ''
            } ${subClassItem.scheduleName}`
          }} -->
          <span v-if="subClassItem.courseSectionRespDTO.data.level3"
            >第{{ subClassItem.courseSectionRespDTO.data.level3 }}课</span
          >

          <a-tooltip placement="topLeft" :key="subClassItem.uuid">
            <template slot="title">
              <span>{{ subClassItem.scheduleName }}</span>
              <span v-if="subClassItem.sectionNameStudent">{{ `(${subClassItem.sectionNameStudent})` }}</span>
            </template>
            <div class="text-container" style="margin: 0 4px">
              <span>{{ subClassItem.scheduleName }}</span>
              <span v-if="subClassItem.sectionNameStudent">{{ `(${subClassItem.sectionNameStudent})` }}</span>
            </div>
          </a-tooltip>
          {{ subClassItem.courseSectionRespDTO.data.level1 ? subClassItem.courseSectionRespDTO.data.level1 + '-' : '' }}
          {{ subClassItem.courseSectionRespDTO.data.level2 ? subClassItem.courseSectionRespDTO.data.level2 + '-' : '' }}
          {{ subClassItem.courseSectionRespDTO.data.level3 ? subClassItem.courseSectionRespDTO.data.level3 : '' }}
          {{
            `${$moment(subClassItem.startChinaDateTime).format('YY/MM/DD')} ${$moment(
              subClassItem.startChinaDateTime,
            ).format('dddd')} ${subClassItem.startChinaDateTime.substring(
              10,
            )} - ${subClassItem.endChinaDateTime.substring(11)}`
          }}
        </p>
        <p v-else>{{ subClassItem.courseSectionName }}{{ subClassItem.scheduleName }}</p>
      </div>
    </div>
    <a-modal
      :visible="previewVisible"
      title="匹配代课"
      :okButtonProps="{
        props: {
          disabled: isDisabled,
          loading: iconLoading,
        },
      }"
      @cancel="cancelSpin"
      :okText="okText"
      @ok="saveSheet"
    >
      <div class="spin-wrap" v-if="activeName === 'init'">
        <a-spin size="large" :spinning="initSpinning" />
        <!-- <div class="spin-txt">自动匹配代课老师中，请稍等···</div> -->
      </div>
      <div class="spin-wrap" v-if="activeName === 'mathTeacher'">
        <a-spin size="large" :spinning="spinning" />
        <div class="spin-txt">自动匹配代课老师中，请稍等···</div>
      </div>
      <div class="spin-wrap" v-if="activeName === 'success'">
        <div>
          <a-icon type="check-circle" style="font-size: 38px; color: #04cb94" />
        </div>
        <div class="spin-txt">匹配成功，点击“确定”后更换老师</div>
        <div class="spin-txt">
          为您匹配到<span style="color: #009cff">{{ `${matchTeacherObj.fullName}(${matchTeacherObj.email})` }}</span>
        </div>
      </div>
      <div class="spin-wrap" v-if="activeName === 'noTeacher'">
        <div>
          <a-icon type="close-circle" style="font-size: 38px; color: rgb(162 163 162)" />
        </div>
        <div class="spin-txt">暂未匹配到代课老师！</div>
        <div class="spin-txt grey-txt" v-if="subject === 'CHINESE'">可以前去下“代课单”，也可选择调整课节</div>
        <div class="spin-txt grey-txt" v-else>可以前去调整课节时间或取消课节</div>
      </div>
      <div class="spin-wrap" v-if="activeName === 'noSheet'">
        <div>
          <a-icon type="warning" style="font-size: 38px; color: #d04141" />
        </div>
        <div class="spin-txt">该任务中存在{{ subject === 'CHINESE' ? 24 : 48 }}小时内课节，无法自动匹配代课！</div>
        <div class="spin-txt grey-txt">可以前去下”代课单“，也可以选择顺延取消课节</div>
      </div>
      <div class="spin-wrap" v-if="activeName === 'teacherForm'">
        <a-form :form="formTeacher" :label-col="{ span: 7 }" :wrapper-col="{ span: 14 }">
          <a-form-item label="期望老师性别">
            <a-select v-decorator="['gender']" placeholder="请选择">
              <a-select-option v-for="item in genderArr" :key="item.value" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="授课语言">
            <a-select mode="multiple" v-decorator="['language']" placeholder="请选择">
              <a-select-option v-for="item in languageOptions" :key="item.value" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="期望老师授课风格">
            <a-select v-decorator="['teachingStyle']" placeholder="请选择" mode="multiple">
              <a-select-option v-for="item in teachingStylesOptions" :key="item.value" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  watch: {
    classItem: {
      handler(v) {
        console.log(v);
      },
      immediate: true,
    },
  },
  props: {
    substitute: {
      type: Boolean,
      default: false,
    },
    classItem: {
      type: Object,
    },
    processInstanceId: {
      type: String,
    },
    subject: {
      type: String,
    },
    taskId: {
      type: String,
    },
    adjustScheduleProcess: {
      type: String,
    },
    upComingTypeValues: {
      type: String,
    },
    showOperate: {
      type: Boolean,
      default: true,
    },
    startDateTimeFrom: {
      type: String,
    },
    startDateTimeTo: {
      type: String,
    },
    teacherId: {
      type: String,
    },
    changeTeacherReason: {
      type: String,
    },
  },
  data() {
    return {
      // 课程类型枚举
      courseTypeMap: {
        STANDARD: '标准课',
        TRIAL: '体验课',
        PUBLIC: '公开课',
      },
      // 班级类型枚举
      classTypeMap: {
        PRIVATE: '私教课',
        GROUP: '班课',
      },
      languageOptions: [
        { label: '中文普通话', value: '中文普通话' },
        { label: '英语', value: '英语' },
        { label: '日语', value: '日语' },
        { label: '法语', value: '法语' },
        { label: '德语', value: '德语' },
        { label: '俄语', value: '俄语' },
        { label: '韩语', value: '韩语' },
        { label: '粤语', value: '粤语' },
        { label: '泰语', value: '泰语' },
        { label: '印尼语', value: '印尼语' },
        { label: '西班牙语', value: '西班牙语' },
        { label: '新加坡英语', value: '新加坡英语' },
        { label: '阿拉伯语', value: '阿拉伯语' },
        { label: '意大利语', value: '意大利语' },
        { label: '其他', value: '其他' },
      ],

      teachingStylesOptions: [
        { label: '认真严谨', value: '认真严谨' },
        { label: '活泼有趣', value: '活泼有趣' },
        { label: '善于拓展', value: '善于拓展' },
        { label: '亲切自然', value: '亲切自然' },
        { label: '经验丰富', value: '经验丰富' },
        { label: '善于引导', value: '善于引导' },
      ],
      genderArr: [
        { label: '男', value: 'M' },
        { label: '女', value: 'F' },
      ],

      formTeacher: this.$form.createForm(this),
      matchTeacherObj: {},
      isDisabled: true,
      iconLoading: false,
      initSpinning: true,
      courseScheduleIds: [],
      showInit: true,
      activeName: 'teacherForm',
      okText: '确定',
      spinning: true,
      cursor: 'cursor',
      previewVisible: false,
      visibleClass: false,
      visibleStudents: false,
      toggleTxt: '详情',
      studnetTxt: '详情',
    };
  },
  methods: {
    adjustFun() {
      const routeUrl = this.$router.resolve({
        path: '/workoutSchedule/classDetail',
        query: { classId: this.classItem.uuid },
      });
      window.open(routeUrl.href, '_blank');
    },
    saveSheet() {
      if (this.activeName === 'noSheet' || this.activeName === 'noTeacher') {
        if (['MATH', 'ENGLISH'].includes(this.subject)) {
          this.cancelSpin();
          return;
        }

        this.$router.push({
          path: '/courseSheet',
          query: {
            classItem: JSON.stringify(this.classItem),
            subject: this.subject,
            processInstanceId: this.processInstanceId,
            type: this.activeName === 'noSheet' ? 'URGENT_SUBSTITUTE_ORDER' : 'NORMAL_SUBSTITUTE_ORDER', // 未匹配到老师，下常规代课单
          },
        });
      } else if (this.activeName === 'success') {
        const params = {
          courseScheduleIds: this.courseScheduleIds,
          teacherId: this.matchTeacherObj.uuid,
          formClassId: this.classItem.uuid,
          substitute: this.substitute,
          source: 'TASK',
          taskId: this.taskId,
          reason: this.changeTeacherReason,
        };
        this.$post(process.env.VUE_APP_API_IM_URL, `/api/admin/scheduling/change/teacher`, params).then((res) => {
          this.$emit('courseScheduleInfo');
          this.cancelSpin();
        });
      }
      // else if (this.activeName === 'noTeacher') {
      //   this.cancelSpin();
      // }
      else if (this.activeName === 'teacherForm') {
        this.formTeacher.validateFields((err, values) => {
          if (!err) {
            // 去匹配代课老师
            this.activeName = 'mathTeacher';
            this.okText = '确定';
            this.queryMathTeacher(values);
          }
        });
      }
    },
    matchClass() {
      const { startDateTimeFrom, startDateTimeTo, teacherId } = this;

      // 查询是否有超过24小时的课节，数学英文为48小时
      const params = {
        formClassId: this.classItem.uuid,
        processInstanceId: this.processInstanceId,
        subject: this.subject,
        teacherId,
        startDateTimeFrom,
        startDateTimeTo,
        courseScheduleIds: this.classItem.scheduleArray?.map((item) => item.uuid),
      };

      this.$post(process.env.VUE_APP_API_IM_URL, `/api/admin/scheduling/distinction/substitute`, params).then((res) => {
        if (!res.data?.content?.substituteEnable && this.subject !== 'CHINESE') {
          this.$message.error('当前包含48小时内课节无法匹配代课');
          return;
        }
        const courseScheduleIds = res.data.content?.courseScheduleIds || [];
        this.courseScheduleIds = courseScheduleIds;

        this.previewVisible = true;
        if (res.data?.content?.substituteEnable) {
          this.activeName = 'teacherForm';
          this.okText = '立即匹配';
          this.isDisabled = false;
        } else {
          this.activeName = 'noSheet';
          this.okText = '发代课单';
          this.isDisabled = false;
        }
      });
    },
    // 匹配授课老师
    queryMathTeacher(values) {
      const params = {
        processInstanceId: this.processInstanceId,
        gender: values.gender,
        teachingStyleList: values.teachingStyle,
        languageList: values.language,
        courseScheduleIds: this.courseScheduleIds,
        formClassId: this.classItem.uuid,
      };
      this.$post(process.env.VUE_APP_API_IM_URL, `/api/task/match/teacher`, params)
        .then((res) => {
          this.isDisabled = false;
          // 判断有没有匹配到授课老师
          this.matchTeacherObj = res.data.content;

          if (this.matchTeacherObj !== null) {
            this.activeName = 'success';
          } else {
            this.activeName = 'noTeacher';

            this.okText = this.subject === 'CHINESE' ? '发代课单' : '我知道了';
          }
        })
        .catch((error) => {
          this.cancelSpin();
        });
    },

    cancelSpin() {
      this.previewVisible = false;
      this.activeName = 'init';
    },
    // 切换收起学生详情
    onUnfoldStudents() {
      this.visibleStudents = !this.visibleStudents;
      this.studnetTxt = this.visibleStudents ? '收起' : '详情';
    },
    // 切换收起详情
    toggleClass() {
      this.visibleClass = !this.visibleClass;
      this.toggleTxt = this.visibleClass ? '收起' : '详情';
    },
    onGetStudentId(data) {
      this.$emit('onGetStudentId', { name: data.name || data.fullName, id: data.id });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .ant-form-item {
  margin: 0px 0px 12px;
}
.concat-con {
  border: 1px solid #eeeeee;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  padding: 10px;
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
}
.concat-item {
  margin-bottom: 5px;
}
.studentHover:hover span {
  cursor: pointer;
  color: #04cb94;
}
.concat-label {
  color: #666;
  font-size: 13px;
  border-left: 1px solid #dddddd;
  padding: 0 10px;
  display: inline-block;
  &:first-child {
    padding: 0 10px 0 0;
    border-left: 0;
  }
}
.bot-border {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  // border-bottom: 1px solid #eeeeee;
  // padding-bottom: 10px;
  & > div {
    width: 100%;
  }
}
.scheduleCls {
  margin-top: 10px;
  padding-top: 5px;
  border-top: 1px solid #eeeeee;
}
.openClassbtn {
  font-size: 13px;
  color: #04cb94;
  padding-left: 15px;
  cursor: pointer;
}
.class-item {
  font-size: 14px;
  margin-bottom: 4px;
}
.spin-wrap {
  text-align: center;
}
.spin-txt {
  margin-top: 10px;
}
.grey-txt {
  color: #838282;
}
.text-container {
  max-width: 300px;
  white-space: nowrap; /* 防止文本折行 */
  overflow: hidden; /* 隐藏超出容器宽度的文本 */
  text-overflow: ellipsis; /* 显示省略号 */
}
</style>
