<template>
  <div :class="subDetailType">
    <div class="task-form">
      <p class="form-name">
        <a-tooltip :overlayStyle="{ maxWidth: '500px' }">
          <template slot="title">
            <div class="task-explainTitleTC">
              <span class="task-explain" @click="lookWord">查看文档说明</span>
            </div>
          </template>
          <i class="explainTC"></i>
        </a-tooltip>
        {{ formInfoData.question }}
        <span class="pending-status" v-if="!formInfoData.finished">待处理</span>
        <span class="success-status" v-if="formInfoData.finished && !formInfoData.deleted">已完成</span>
        <span class="refuse-status" v-if="formInfoData.finished && formInfoData.deleted">已拒绝</span>
      </p>
      <div class="form-teacher">
        <img :src="defaultImg" alt="" />
        <span
          >{{ formInfoData.startUserName }}
          <span v-if="formInfoData.startUserName === '系统'">自动</span>
          <span v-else>（ {{ formInfoData.userRole }} ） </span>
        </span>
        <span v-if="formInfoData.startUserName !== '系统自动生成'">提交于</span>
        <span>{{ $moment(formInfoData.startTime).utcOffset(8).format('YYYY年MM月DD日 HH:mm') }}</span>
        | 最迟解决时间
        <span
          v-if="formInfoData.endTime"
          :class="{ dueDate: new Date(formInfoData.endTime).getTime() > new Date(formInfoData.dueDate).getTime() }"
          >{{ $moment(formInfoData.dueDate).format('YYYY年MM月DD日 HH:mm')
          }}{{
            new Date(formInfoData.endTime).getTime() > new Date(formInfoData.dueDate).getTime() ? '(逾期)' : ''
          }}</span
        >
        <span v-else :class="{ dueDate: new Date().getTime() > new Date(formInfoData.dueDate).getTime() }"
          >{{ $moment(formInfoData.dueDate).format('YYYY年MM月DD日 HH:mm')
          }}{{ new Date().getTime() > new Date(formInfoData.dueDate).getTime() ? '(逾期)' : '' }}</span
        >
      </div>
      <div class="form-desc form-desc1">
        <span class="red-icon">*</span>
        <span>交接发起人(老师)</span>
      </div>
      <div class="form-desc form-desc2">
        {{ formInfoData.teacherName || formInfoData.startUserName }}
        <span v-if="formInfoData.userRole">（{{ formInfoData.userRole }}）</span>
      </div>
      <!-- <div class="form-desc form-desc1">
        <span class="red-icon">*</span>
        <span class="form-label">交接合规</span>
      </div> -->
      <!-- <div class="form-desc form-desc2">{{ formInfoData.reasonable }}</div> -->
      <!-- <template v-if="formInfoData.takeOverPeriod">
        <div class="form-desc form-desc1">
          <span class="red-icon">*</span>
          <span class="form-label">交接周期</span>
        </div>
        <div class="form-desc form-desc2">{{ formInfoData.takeOverPeriod }}</div></template
      > -->
      <template v-if="formInfoData.subject === 'CHINESE' && detailType === 'TAKE_OVER'">
        <div class="form-desc form-desc1">
          <span class="red-icon">*</span>
          <span>首课时间（北京）</span>
        </div>
        <div class="form-desc form-desc2">
          {{ formInfoData.firstCourseDate }}
        </div>
      </template>
      <div class="form-desc form-desc1">
        <span class="red-icon">*</span>
        <span class="form-label">对外话术</span>
      </div>
      <div class="form-desc form-desc2">
        老师因个人原因无法继续授课，为保障课堂质量和学习效果，特此交接班级，感谢您的配合
      </div>
      <!-- 中文数据兼容 -->
      <template v-if="formInfoData.classState">
        <div class="form-desc form-desc1">
          <span class="red-icon">*</span>
          <span>班级情况交接</span>
        </div>
        <div class="form-desc form-desc2">
          {{ formInfoData.classState }}
        </div>
      </template>
      <!-- <div class="form-desc form-desc1">
        <span class="red-icon">*</span>
        <span>老师首课时间(北京)</span>
      </div> -->
      <!-- <div class="form-desc form-desc2" v-if="formInfoData.firstSchedule">
        {{ formInfoData.firstSchedule }}
      </div> -->
      <div class="form-desc form-desc1">
        <span class="red-icon">*</span>
        <span>最后上课课节(北京)</span>
      </div>
      <div class="form-desc form-desc2">{{ formInfoData.lastCourseDate }}</div>
      <!-- <template v-if="formInfoData.newSchedule">
        <div class="form-desc form-desc1">
          <span class="red-icon">*</span>
          <span>新老师首课(北京)</span>
        </div>
        <div class="form-desc form-desc2">{{ formInfoData.newSchedule }}</div>
      </template> -->
      <!-- 中文数据兼容 -->
      <template v-if="formInfoData.upDescription">
        <div class="form-desc form-desc1">
          <span class="red-icon">*</span>
          <span>升级后开课内容</span>
        </div>
        <div class="form-desc form-desc2">{{ formInfoData.upDescription }}</div>
      </template>
      <!-- 中文数据兼容 -->
      <template v-if="formInfoData.upgradeProgress">
        <div class="form-desc form-desc1">
          <span class="red-icon">*</span>
          <span>下节课上课课节</span>
        </div>
        <div class="form-desc form-desc2">{{ formInfoData.upgradeProgress }}</div>
      </template>
      <!-- <div class="form-desc form-desc1" v-if="formInfoData.outProcedure">
        <span class="red-icon">*</span>
        <span>交接真实原因</span>
      </div>
      <div class="form-desc form-desc2" v-if="formInfoData.outProcedure">{{ formInfoData.outProcedure || '无' }}</div>

      <div class="form-desc form-desc1" v-if="formInfoData.outProcedure === '其他'">
        <span class="red-icon">*</span>
        <span>其他交接原因</span>
      </div>
      <div class="form-desc form-desc2" v-if="formInfoData.outProcedure === '其他'">
        {{ formInfoData.reason || '无' }}
      </div> -->
      <template v-if="formInfoData.turnManualReason">
        <div class="form-desc form-desc1">
          <span class="red-icon">*</span>
          <span>转人工原因</span>
        </div>
        <div class="form-desc form-desc2">
          {{ turnManualReasonTextMap[formInfoData.turnManualReason] }}
        </div>
      </template>
    </div>

    <RelatedStudents :formInfoData="formInfoData" @onGetStudentId="onGetStudentId" changeTeacherReason="老师交接班" />
    <div class="concat-wrap">
      <div class="title">关联班级</div>
      <RelatedClass
        changeTeacherReason="老师交接班"
        :processInstanceId="formInfoData.processInstanceId"
        :adjustScheduleProcess="formInfoData.adjustScheduleProcess"
        :upComingTypeValues="formInfoData.upComingTypeValues"
        :taskId="formInfoData.taskId"
        :showOperate="
          formInfoData.subject && formInfoData.subject !== 'CHINESE' && detailType !== 'TEACHER_TAKE_OVER_FOLLOW'
        "
        class="concat-con"
        v-for="(classItem, index) in formInfoData.relatedClass"
        :classItem="classItem"
        :key="index"
        :subject="formInfoData.subject"
        :startDateTimeFrom="$moment(startDateTimeFrom).add(1, 'minutes').format('YYYY-MM-DD HH:mm:ss')"
        :teacherId="formInfoData.teacherId"
        @onGetStudentId="onGetStudentId"
        @courseScheduleInfo="courseScheduleInfo"
      >
      </RelatedClass>
    </div>
  </div>
</template>

<script>
import defaultImg from '../assets/service_pic_head.png';
import RelatedClass from './RelatedClass.vue';
import RelatedStudents from './RelatedStudents.vue';

export default {
  components: { RelatedClass, RelatedStudents },
  computed: {
    startDateTimeFrom() {
      const { lastCourseDate } = this.formInfoData;
      if (!lastCourseDate) return undefined;
      const lastCourseDateInfos = lastCourseDate.split(' ');
      const [, eTime] = lastCourseDateInfos[lastCourseDateInfos.length - 1].split('-');
      const date = lastCourseDateInfos[lastCourseDateInfos.length - 2];
      const time = `${date} ${eTime}:00`;
      return time;
    },
  },
  watch: {
    formInfoData: {
      handler(v) {
        console.log(v);
      },
      immediate: true,
    },
  },
  props: {
    formInfoData: {
      type: Object,
    },
    subDetailType: {
      // 区分是老师还是班主任，班主任主题色是绿色
      type: String,
      default: 'teacher',
    },
    detailType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      cursor: 'cursor',
      defaultImg,
      isCarousel: false,
      pictures: [],
      visibleClass: true,
      toggleTxt: '收起',
      turnManualReasonTextMap: {
        COURSE_SCHEDULE_CHANGE: '”改约“导致取消排课单',
        COURSE_SCHEDULE_CANCEL: '人工取消课节导致系统的排课单被取消',
        SCHEDULE_ADJUST: '人工调整课节进度后导致系统的排课单被取消',
        TIME_ADJUST: '人工调整上课时间后导致系统的排课单被取消',
        TEACHER_ADJUST: '人工更改授课老师后导致系统的排课单被取消',
        STUDENT_LEAVE: '家长请假后导致系统的排课单被取消',
        SCHEDULE_ADJUST_FAIL: '课节更换老师或课节被取消，导致课节进度调整失败',
        SCHEDULE_ORDER_EXISTS: '老师请假时班级内有发起的排课单',
        DIRECTLY_CANCEL: '直接取消',
        EXIST_48_HOUR_SCHEDULE: '有48小时内开课课节',
        EXIST_2_HOUR_SCHEDULE: '有2小时内开课课节',
        AVAILABLE_TEACHER_NOT_FOUND: '系统未匹配到老师',
        PUBLIC_TURN: '公开课转人工',
        PRIVATE_TURN: '私教课转人工',
      },
    };
  },
  methods: {
    courseScheduleInfo() {
      this.$emit('courseScheduleInfo');
    },
    lookWord() {
      window.open('https://wukongedu.feishu.cn/wiki/wikcnVtCp6s3prid9TmohjA1iSb?sheet=h2ZIz4', '_blank');
    },
    // 切换收起详情
    toggleClass() {
      this.visibleClass = !this.visibleClass;
      this.toggleTxt = this.visibleClass ? '收起' : '详情';
    },
    onGetStudentId(data) {
      this.$emit('onGetStudentId', data);
    },
    onPictures(datas) {
      this.pictures = [];
      this.isCarousel = true;
      this.pictures = datas;
      this.$emit('onPictures', datas);
    },
    onCloseImg() {
      this.isCarousel = false;
    },
  },
};
</script>

<style lang="less" scoped>
.cursor {
  cursor: pointer;
  color: #009cff !important;
}
.classAdmin .cursor {
  cursor: pointer;
  color: #43d186 !important;
}
.title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 20px;
}
.title::before {
  content: '';
  display: block;
  float: left;
  margin: 4px 12px 0 0;
  width: 4px;
  height: 20px;
  background-color: #009cff;
  border-radius: 0px 6px 6px 0px;
}
.task-form {
  border-bottom: 1px solid #eee;
}
.form-name {
  font-size: 20px;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
.form-name span {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 2px 10px;
  border-radius: 6px;
  margin: 0 10px;
}
.pending-status {
  color: #ffbf00;
  background-color: rgba(255, 191, 0, 0.09);
}
.success-status {
  color: #00cf64;
  background-color: rgba(0, 207, 100, 0.09);
}
.refuse-status {
  color: #ff5353;
  background-color: rgba(255, 83, 83, 0.09);
}
.form-teacher {
  font-size: 16px;
  font-weight: 400;
  color: #999;
  line-height: 22px;
  margin-bottom: 15px;
}
.form-teacher .dueDate {
  color: #ff5454;
}
.form-teacher span {
  color: #333;
}
.form-teacher img {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 10px;
}
.form-des {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  margin-bottom: 15px;
}
.form-imgs {
  margin-bottom: 20px;
}
.form-imgs img {
  width: 80px;
  height: 80px;
  border-radius: 4px;
  margin-right: 10px;
}
.courseInfo {
  padding: 20px 0;
}
.course-info {
  padding: 20px 16px;
  background: linear-gradient(180deg, #ecf7ff 0%, #f7fcff 100%);
  border-radius: 20px;
  margin-bottom: 20px;
}
.course-name {
  font-size: 16px;
  font-weight: 600;
  color: #009cff;
  line-height: 22px;
  margin-bottom: 10px;
}
.course-info a:hover {
  text-decoration: underline;
}
.course-type {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 18px;
  margin-top: 10px;
}
.carousel-main {
  width: 550px;
  height: 340px;
  background: #fff;
  border-radius: 20px;
}
.carousel-main .imgcls {
  width: 550px;
  height: 340px;
}
.carousel-main .imgcls img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.carousel-main .ant-carousel .slick-slide {
  text-align: center;
  height: 340px;
}
.carousel-main .ant-carousel .custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #000;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
}

.carousel-main .ant-carousel .custom-slick-arrow:before {
  display: none;
}
.carousel-main .ant-carousel .custom-slick-arrow:hover {
  opacity: 0.5;
}
.student-item {
  display: flex;
  justify-content: flex-start;
}
.student-item p {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.student-item img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 7px;
}
.student-item .student-name {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  line-height: 22px;
}
.form-desc {
  color: #333;
  font-size: 16px;
  display: flex;
}
.form-desc1 {
  margin-bottom: 6px;
}
.form-desc2 {
  margin-bottom: 20px;
  padding-left: 8px;
}
.red-icon {
  color: #ff5353;
}
.form-label {
  width: 85px;
}
.form-reason {
  flex: 1;
}
.title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 20px;
}
.title::before {
  content: '';
  display: block;
  float: left;
  margin: 4px 12px 0 0;
  width: 4px;
  height: 20px;
  background-color: #009cff;
  border-radius: 0px 6px 6px 0px;
}
.concat-wrap {
  padding-top: 20px;
  margin-bottom: 20px;
}
.concat-con {
  border: 1px solid #eeeeee;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  padding: 10px;
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
}
.concat-item {
  margin-bottom: 5px;
}
.concat-label {
  color: #666;
  font-size: 13px;
  border-left: 1px solid #dddddd;
  padding: 0 10px;
  display: inline-block;
  &:first-child {
    padding: 0 10px 0 0;
    border-left: 0;
  }
}
.bot-border {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px;
}

.class-item {
  font-size: 14px;
  margin-bottom: 4px;
}
.student-item {
  display: flex;
  justify-content: flex-start;
  margin-right: 20px;
}
.student-item p {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.student-item img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 7px;
}
.student-item .student-name {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  line-height: 22px;
}
.student-wrap {
  display: flex;
  margin-bottom: 22px;
}
.classAdmin {
  .title::before {
    background-color: #43d186;
  }
  .course-name {
    color: #43d186;
  }
}
.explainTC {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('../assets/explain.png') no-repeat center;
  background-size: 100%;
  cursor: pointer;
  margin-right: 5px;
}
.task-explainTitleTC {
  padding-bottom: 5px;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.task-titleTxt {
  font-size: 18px;
  font-weight: 600;
}
.task-explain {
  color: #2eaaf9;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}
</style>
