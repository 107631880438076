<template>
  <div>
    <div class="task-form">
      <p class="form-name">
        {{ formInfoData.question }}
        <span class="pending-status" v-if="!formInfoData.finished">待处理</span>
        <span class="success-status" v-if="formInfoData.finished && !formInfoData.deleted">已完成</span>
        <span class="refuse-status" v-if="formInfoData.finished && formInfoData.deleted">已拒绝</span>
      </p>
      <div class="form-teacher">
        <img :src="defaultImg" alt="" />
        <span
          >{{ formInfoData.startUserName }}（ <span>{{ formInfoData.userRole }}</span> ）</span
        >
        提交于
        <span>{{ $moment(formInfoData.startTime).utcOffset(8).format('YYYY年MM月DD日 HH:mm') }}</span>
        | 最迟解决时间
        <span
          v-if="formInfoData.endTime"
          :class="{ dueDate: new Date(formInfoData.endTime).getTime() > new Date(formInfoData.dueDate).getTime() }"
          >{{ $moment(formInfoData.dueDate).format('YYYY年MM月DD日 HH:mm')
          }}{{
            new Date(formInfoData.endTime).getTime() > new Date(formInfoData.dueDate).getTime() ? '(逾期)' : ''
          }}</span
        >
        <span v-else :class="{ dueDate: new Date().getTime() > new Date(formInfoData.dueDate).getTime() }"
          >{{ $moment(formInfoData.dueDate).format('YYYY年MM月DD日 HH:mm')
          }}{{ new Date().getTime() > new Date(formInfoData.dueDate).getTime() ? '(逾期)' : '' }}</span
        >
      </div>
      <div class="form-des" v-if="detailType === 'SCHEDULE_RELATED'">
        <p>原因：{{ formInfoData.reasons ? formInfoData.reasons.join('、') : '-' }}</p>
      </div>
      <!-- 课节进度调整 -->
      <div class="form-des" v-if="detailType === 'LESSON_PROGRESS_RELATED'">
        <p>调整目标：{{ formInfoData.adjustTarget }}</p>
      </div>
      <div class="form-des" v-if="formInfoData.reason">
        <p>异常原因：{{ formInfoData.reason }}</p>
      </div>
      <div class="form-des">
        <p v-if="formInfoData.businessKey !== 'attendance-form'">{{ formInfoData.description }}</p>
        <p v-else-if="formInfoData.description">详细描述：{{ formInfoData.description }}</p>
      </div>
      <div class="form-imgs" v-if="formInfoData.imgs">
        <img v-for="item in formInfoData.imgs.split(',')" :src="item" preview="1" :key="item" alt="" />
      </div>
    </div>
    <div class="courseInfo" v-if="formInfoData.courseInfo">
      <div class="title">
        <span>关联课节</span>
      </div>
      <div class="course-info">
        <router-link
          v-if="formInfoData.showcourseSection"
          class="course-name"
          :to="{ path: '/courseDetails', query: { uuid: formInfoData.courseInfo.uuid } }"
        >
          {{ formInfoData.courseInfo.courseName }}
          {{ formInfoData.courseInfo.scheduleName }}
        </router-link>
        <span v-if="!formInfoData.showcourseSection" class="course-name"
          >{{ formInfoData.courseInfo.courseName }} {{ formInfoData.courseInfo.scheduleName }}</span
        >
        <div class="course-type">
          <span>开课时间：{{ formInfoData.courseInfo.startTime }}</span>
        </div>
        <div class="course-type">
          <span>{{ formInfoData.courseInfo.courseSectionName }}</span> |
          <span>{{ formInfoData.courseInfo.classType === 'PRIVATE' ? '私教课' : '班课' }}</span> |
          <span>学生：{{ formInfoData.courseInfo.studentName || '无' }}</span> |
          <span>班级：{{ formInfoData.courseInfo.formClassName }}</span>
        </div>
      </div>
      <RelatedStudents :formInfoData="formInfoData" @onGetStudentId="onGetStudentId" title="关联人员" />
    </div>
  </div>
</template>

<script>
import defaultImg from '../assets/service_pic_head.png';
import RelatedStudents from './RelatedStudents.vue';

export default {
  props: {
    formInfoData: {
      type: Object,
    },
    detailType: {
      type: String,
    },
  },
  components: { RelatedStudents },
  data() {
    return {
      cursor: 'cursor',
      defaultImg,
      pictures: [],
    };
  },
  mounted() {
    this.$emit('courseScheduleInfo');
  },
  methods: {
    onGetStudentId(data) {
      this.$emit('onGetStudentId', { name: data.name, id: data.id });
    },
    onPictures(datas) {
      this.$emit('onPictures', datas);
    },
  },
};
</script>

<style lang="less" scoped>
.cursor {
  cursor: pointer;
  color: #009cff !important;
}
.classAdmin .cursor {
  cursor: pointer;
  color: #43d186 !important;
}
.title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 20px;
}
.title::before {
  content: '';
  display: block;
  float: left;
  margin: 4px 12px 0 0;
  width: 4px;
  height: 20px;
  background-color: #009cff;
  border-radius: 0px 6px 6px 0px;
}
.task-form {
  border-bottom: 1px solid #eee;
}
.form-name {
  font-size: 20px;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 15px;
}
.form-name span {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 2px 10px;
  border-radius: 6px;
  margin: 0 10px;
}
.pending-status {
  color: #ffbf00;
  background-color: rgba(255, 191, 0, 0.09);
}
.success-status {
  color: #00cf64;
  background-color: rgba(0, 207, 100, 0.09);
}
.refuse-status {
  color: #ff5353;
  background-color: rgba(255, 83, 83, 0.09);
}
.form-teacher {
  font-size: 16px;
  font-weight: 400;
  color: #999;
  line-height: 22px;
  margin-bottom: 15px;
}
.form-teacher .dueDate {
  color: #ff5454;
}
.form-teacher span {
  color: #333;
}
.form-teacher img {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 10px;
}
.form-des {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  margin-bottom: 15px;
}
.form-imgs {
  margin-bottom: 20px;
}
.form-imgs img {
  width: 80px;
  height: 80px;
  border-radius: 4px;
  margin-right: 10px;
}
.courseInfo {
  padding: 20px 0;
}
.course-info {
  padding: 20px 16px;
  background: linear-gradient(180deg, #ecf7ff 0%, #f7fcff 100%);
  border-radius: 20px;
  margin-bottom: 20px;
}
.course-name {
  font-size: 16px;
  font-weight: 600;
  color: #009cff;
  line-height: 22px;
  margin-bottom: 10px;
}
.course-info a:hover {
  text-decoration: underline;
}
.course-type {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 18px;
  margin-top: 10px;
}

.student-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.student-item p {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.student-item img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 7px;
}
.student-item .student-name {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  line-height: 22px;
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
