<template>
  <div class="test-main" :class="subDetailType">
    <div class="task-info">
      <div class="task-type">
        <span>{{ formInfoData.category.substring(formInfoData.category.indexOf('】') + 1) }}</span>
        <span>{{
          formInfoData.category.substring(formInfoData.category.indexOf('【') + 1, formInfoData.category.indexOf('】'))
        }}</span>
        <span v-if="formInfoData.courseType">{{ formInfoData.courseType }}</span>
        <span v-if="formInfoData.classType">{{ formInfoData.classType }}</span>
      </div>

      <!-- 管理员交接班 -->
      <AdminHandover
        v-if="detailType === 'ADMIN_TAKE_OVER'"
        :formInfoData="formInfoData"
        @onGetStudentId="onGetStudentId"
        @courseScheduleInfo="courseScheduleInfo"
      />
      <!-- 扣款失败 -->
      <SubscriptionDeductionFailedDetail
        v-if="detailType === 'SUBSCRIPTION_DEDUCTION_FAILED_TASK'"
        @onGetStudentId="onGetStudentId"
        :formInfoData="formInfoData"
      >
      </SubscriptionDeductionFailedDetail>
      <!-- 挽单 -->
      <WinBackStrategy
        v-if="detailType === 'ATTEMPT_RETENTION_ON_CANCELLATION'"
        @onGetStudentId="onGetStudentId"
        :formInfoData="formInfoData"
      >
      </WinBackStrategy>
      <!--竞赛课班主任通知任务-->
      <BookClassContest
        v-if="detailType === 'TASK_TOPIC_COURSE_SELECTION_FOLLOW_UP'"
        @onGetStudentId="onGetStudentId"
        :formInfoData="formInfoData"
      ></BookClassContest>

      <!--私教课加课详情-->
      <PrivateLessonsDetail
        v-if="detailType === 'TASK_PRIVATE_CLASS_REMINDER'"
        @onGetStudentId="onGetStudentId"
        :formInfoData="formInfoData"
      ></PrivateLessonsDetail>
      <!--不续费任务-->
      <NoRenewalDetail
        v-if="detailType === 'TASK_NOT_RENEW'"
        :taskType="detailType"
        :formInfoData="formInfoData"
        :subDetailType="subDetailType"
        @onGetStudentId="onGetStudentId"
      >
      </NoRenewalDetail>
      <!--约课任务-->
      <AppointmentTaskDetail
        v-if="detailType === 'TASK_SUBSCRIBE_COURSE' || detailType === 'TASK_TRACK_CREATE'"
        :taskType="detailType"
        :formInfoData="formInfoData"
        :subDetailType="subDetailType"
        @onGetStudentId="onGetStudentId"
        @onHrefClue="onHrefClue"
      >
      </AppointmentTaskDetail>
      <!--学生页面点击待办任务的详情-->

      <ClassAdminDetail
        @playCourse="playCourse"
        @playClass="playClass"
        v-if="
          detailType === 'TASK_CREATE_GROUP' ||
          detailType === 'NEW_STUDENT_FOLLOW_UP' ||
          detailType === 'TASK_SCHEDULE' ||
          detailType === 'TASK_FIRST_VISIT' ||
          detailType === 'TASK_PROMOTE' ||
          detailType === 'TASK_CLASS_ADMIN_FIRST_PASS' ||
          detailType === 'TASK_CLASS_ADMIN_JOIN' ||
          detailType === 'TRANSFER_STUDENT_FOLLOW_UP' ||
          detailType === 'TASK_MOTH_VISIT' ||
          detailType === 'TASK_W2_VISIT' ||
          detailType === 'TASK_W3_VISIT' ||
          detailType === 'TASK_WEEK_VISIT' ||
          detailType === 'REVIEW_QUESTION_REMINDER' ||
          detailType === 'TASK_TERM_VISIT' ||
          detailType === 'TASK_STUDENT_ACTIVE' ||
          detailType === 'TASK_MOTH_STUDY_VISIT'
        "
        @onGetStudentId="onGetStudentId"
        :detailType="detailType"
        :formInfoData="formInfoData"
        :subDetailType="subDetailType"
      ></ClassAdminDetail>
      <!--新生首通-->
      <FirstPassDetail
        @playCourse="playCourse"
        @playClass="playClass"
        @ToScheduleClasses="ToScheduleClasses"
        v-if="['TASK_FIRST_PASS', 'TASK_FIRST_FOLLOW_UP'].includes(detailType)"
        @onGetStudentId="onGetStudentId"
        :detailType="detailType"
        :formInfoData="formInfoData"
        :subDetailType="subDetailType"
      ></FirstPassDetail>
      <!--授课老师旷课-->
      <AbsenteeismDetail
        v-if="detailType === 'TASK_TEACHER_ABSENT'"
        @onGetStudentId="onGetStudentId"
        :formInfoData="formInfoData"
        :subDetailType="subDetailType"
      ></AbsenteeismDetail>
      <!-- 教学反馈 -->
      <FeedBackDetail
        v-if="detailType === 'SCHEDULE_FEEDBACK'"
        :formInfoData="formInfoData"
        @onGetStudentId="onGetStudentId"
      />
      <!--老师迟到-->
      <TeacherIsLateDetail
        v-if="detailType === 'TEACHER_LATE'"
        @onGetStudentId="onGetStudentId"
        :formInfoData="formInfoData"
        :subDetailType="subDetailType"
        @playCourse="playCourse"
        @playClass="playClass"
      ></TeacherIsLateDetail>
      <!--寄送订单-->
      <SendOrderDetail
        v-if="detailType === 'TASK_CONFIRM_ORDER'"
        :formInfoData="formInfoData"
        :subDetailType="subDetailType"
        @onGetStudentId="onGetStudentId"
        @handleConfirmAddress="handleConfirmAddress"
      ></SendOrderDetail>
      <!--数学订单审批-->
      <MathOrderApproval
        v-if="detailType === 'SUBMIT_ORDER_TASK'"
        :formInfoData="formInfoData"
        @onGetStudentId="onGetStudentId"
        @onOrder="onOrder"
      ></MathOrderApproval>
      <!--学生结业证书-->
      <CertificateReportClass
        v-if="detailType === 'TASK_CERTIFICATE'"
        :formInfoData="formInfoData"
        @onClassReport="onCertificateReport"
        @onStudyReport="onStudyReport"
        @onGetStudentId="onGetStudentId"
        @sendCertificateReport="sendCertificateReport"
      ></CertificateReportClass>
      <!--补升订单审批-->
      <OrderApproval
        @onGetStudentId="onGetStudentId"
        @onMakeUp="onMakeUp"
        @onPictures="onPictures"
        :formInfoData="formInfoData"
        v-if="detailType === 'PROMOTE_EXPIRED_TASK'"
      ></OrderApproval>
      <!--重复线索-->
      <RepetitiveClues
        @onGetStudentId="onGetStudentId"
        :formInfoData="formInfoData"
        :subDetailType="subDetailType"
        v-if="detailType === 'TASK_REPEAT_TRACK'"
      ></RepetitiveClues>
      <!--班主任离职交接学生-->
      <ResignStudent
        @openConTaskDeatil="openConTaskDeatil"
        @onGetStudentId="onGetStudentId"
        v-if="detailType === 'TASK_CLASS_ADMIN_RESIGN_STUDENT'"
        :formInfoData="formInfoData"
      ></ResignStudent>
      <!--学员跟进-->
      <StudentFollowUp
        @openConTaskDeatil="openConTaskDeatil"
        @onGetStudentId="onGetStudentId"
        v-if="detailType === 'TASK_NEXT_FOLLOW_RECORD'"
        :formInfoData="formInfoData"
      ></StudentFollowUp>
      <!--课时有效期延期提醒-->
      <PeriodValidityDetail
        @onGetStudentId="onGetStudentId"
        v-if="detailType === 'TASK_STUDENT_CU_EXPIRE_DATE_REMINDER'"
        :formInfoData="formInfoData"
      ></PeriodValidityDetail>
      <!--流失学员首通-->
      <FirstLostStudents
        @openConTaskDeatil="openConTaskDeatil"
        @onGetStudentId="onGetStudentId"
        v-if="detailType === 'TASK_LOSS_REFUND_STUDENT_FIRST_PASS'"
        :formInfoData="formInfoData"
      ></FirstLostStudents>
      <!--班主任离职申请-->
      <ResignTakeOver
        v-if="detailType === 'TASK_CLASS_ADMIN_RESIGN_TAKE_OVER'"
        :formInfoData="formInfoData"
      ></ResignTakeOver>
      <!--老师交接班级后的首课回访-->
      <FirstTeacherVisit
        :detailType="detailType"
        @openConTaskDeatil="openConTaskDeatil"
        @onGetStudentId="onGetStudentId"
        :formInfoData="formInfoData"
        @playCourse="playCourse"
        @playClass="playClass"
        v-if="detailType === 'TASK_CHANGE_TEACHER_VISIT' || detailType === 'TASK_LONG_TASK_OVER_VISIT'"
      ></FirstTeacherVisit>
      <!--判断一下，课节进度，排课相关，学生相关，考勤相关用这个详情-->
      <ScheduleDetail
        v-if="
          detailType === 'LESSON_PROGRESS_RELATED' ||
          detailType === 'SCHEDULE_RELATED' ||
          detailType === 'STUDENT_RELATED' ||
          detailType === 'ATTENDANCE_RELATED'
        "
        @onGetStudentId="onGetStudentId"
        @onPictures="onPictures"
        :formInfoData="formInfoData"
        :detailType="detailType"
        @courseScheduleInfo="courseScheduleInfo"
      ></ScheduleDetail>
      <!--请假、请假跟进详情-->
      <AskforLeaveDetail
        v-if="
          detailType === 'TEACHER_LEAVE' ||
          detailType === 'CHINESE_TEACHER_LEAVE' ||
          detailType === 'APPLICATION_FOR_TEACHER_LEAVE' ||
          detailType === 'APPLICATION_FOR_TEACHER_EN' ||
          detailType === 'TEACHER_LEAVE_FOLLOW'
        "
        :detailType="detailType"
        :formInfoData="formInfoData"
        @onPictures="onPictures"
        @onGetStudentId="onGetStudentId"
        @courseScheduleInfo="courseScheduleInfo"
      ></AskforLeaveDetail>
      <!--花果学习报告详情-->
      <StudyReportClass
        v-if="detailType === 'STAGE_LEARNING_REPORT' && subDetailType === 'classAdmin'"
        :formInfoData="formInfoData"
        :isCheak="isCheak"
        @onStudyReport="onStudyReport"
        @onGetStudentId="onGetStudentId"
        @onClassReport="onClassReport"
        @sendStudyReport="sendStudyReport"
      ></StudyReportClass>
      <!--升级交接班详情、升级交接班跟进详情-->
      <UpgradeHandoverDetail
        v-if="
          (detailType === 'TAKE_OVER' && formInfoData.processType === 'upgrade-take-over') ||
          ['TEACHER_TAKE_OVER_FOLLOW', 'TAKE_OVER_EN'].includes(detailType)
        "
        @onGetStudentId="onGetStudentId"
        @courseScheduleInfo="courseScheduleInfo"
        :formInfoData="formInfoData"
        :detailType="detailType"
      ></UpgradeHandoverDetail>
      <!--调整时间交接班-->
      <TimeHandoverDetail
        v-if="detailType === 'TAKE_OVER' && formInfoData.processType === 'adjust-time-take-over'"
        @onGetStudentId="onGetStudentId"
        :formInfoData="formInfoData"
      ></TimeHandoverDetail>
      <!--离职详情-->
      <ResignationDetail
        v-if="
          (['TAKE_OVER', 'RESIGN_TAKE_OVER_EN'].includes(detailType) &&
            formInfoData.processType === 'resign-take-over') ||
          detailType === 'RESIGN_TAKE_OVER' ||
          detailType === 'RESIGN_TAKE_OVER_APPLY' ||
          detailType === 'CHINESE_RESIGN_TAKE_OVER' ||
          detailType === 'RESIGN_TAKE_OVER_RESCIND' ||
          detailType === 'TEACHER_RESIGN_TAKE_OVER_FOLLOW'
        "
        @onGetStudentId="onGetStudentId"
        :detailType="detailType"
        :formInfoData="formInfoData"
        @onClassResignation="onClassResignation"
        @courseScheduleInfo="courseScheduleInfo"
      ></ResignationDetail>
      <!--教学级别反馈-->
      <TeacherNoAbilityDetail
        v-if="detailType === 'TEACHER_NO_ABILITY'"
        @onGetStudentId="onGetStudentId"
        :detailType="detailType"
        :formInfoData="formInfoData"
        @onClassResignation="onClassResignation"
      ></TeacherNoAbilityDetail>
      <!--甩班申诉-->
      <ShiftAppealDetail
        v-if="detailType === 'TEACHER_SHIFT_APPEAL'"
        @onGetStudentId="onGetStudentId"
        :detailType="detailType"
        :formInfoData="formInfoData"
        @onClassResignation="onClassResignation"
      ></ShiftAppealDetail>
      <!--违规数据-->
      <LeaveAppealDetail
        v-if="detailType === 'TEACHER_LEAVE_APPEAL'"
        @onGetStudentId="onGetStudentId"
        :detailType="detailType"
        :formInfoData="formInfoData"
        @onClassResignation="onClassResignation"
      ></LeaveAppealDetail>
      <!--QA-->
      <QaAppealDetail
        v-if="detailType === 'TEACHER_QA_APPEAL'"
        @onGetStudentId="onGetStudentId"
        :detailType="detailType"
        :formInfoData="formInfoData"
        @onClassResignation="onClassResignation"
      ></QaAppealDetail>
      <!--感谢有你-->
      <ThanksDetail
        v-if="detailType === 'TEACHER_THANKS' || detailType === 'TEACHER_COMPLAINT'"
        @onGetStudentId="onGetStudentId"
        :detailType="detailType"
        :formInfoData="formInfoData"
        @onClassResignation="onClassResignation"
      ></ThanksDetail>
      <!--赠课审批-->
      <ApproveTypeDetail
        v-if="detailType === 'TASK_STUDENT_SHARE_AUDIT'"
        :formInfoData="formInfoData"
        @onPictures="onPictures"
        @onGetStudentId="onGetStudentId"
      ></ApproveTypeDetail>
      <!--学员缺勤-->
      <StudentAbsentDetail
        v-if="detailType === 'TASK_STUDENT_ABSENT'"
        :formInfoData="formInfoData"
        @onGetStudentId="onGetStudentId"
      ></StudentAbsentDetail>
      <!--时令变化-->
      <TimezoneChange
        v-if="detailType === 'DAYLIGHT_TIME_CHANGED'"
        :formInfoData="formInfoData"
        @onGetStudentId="onGetStudentId"
      ></TimezoneChange>
      <!--原，新班主任交接-->
      <HandoverTeacher
        v-if="
          detailType === 'TASK_RESIGN_STUDENT_OLD_CLASSADMIN' || detailType === 'TASK_RESIGN_STUDENT_NEW_CLASSADMIN'
        "
        :taskType="detailType"
        @onGetStudentId="onGetStudentId"
        @lookStudentInfo="lookStudentInfo"
        :formInfoData="formInfoData"
      ></HandoverTeacher>
      <!--班主任交接学员首通-->
      <TaskClassAdminFirstPassV2
        v-if="['TASK_CLASS_ADMIN_FIRST_PASS_V2', 'TASK_CLASS_ADMIN_FIRST_PASS_FOLLOW_UP_V2'].includes(detailType)"
        @onGetStudentId="onGetStudentId"
        @lookStudentInfo="lookStudentInfo"
        :formInfoData="formInfoData"
      />
      <!--一家多娃任务-->
      <FamilyOfDollsDetail
        v-if="detailType === 'TASK_STUDENT_MULTI_CHILD'"
        :taskType="detailType"
        :formInfoData="formInfoData"
        :subDetailType="subDetailType"
        @onGetStudentId="onGetStudentId"
      >
      </FamilyOfDollsDetail>
      <LowerTestDetail
        v-if="detailType === 'LOW_SCORE_TEST'"
        :formInfoData="formInfoData"
        @onGetStudentId="onGetStudentId"
      />

      <div class="processing-records">
        <div class="title">
          <span>处理记录</span>
        </div>
        <div style="padding: 0 0 50px">
          <template v-if="formInfoData.assigneesList.length > 0">
            <MyProcessingRecords :class="subDetailType" :assigneesDatas="formInfoData.assigneesList" />
          </template>
        </div>
      </div>
      <div
        class="taskFormBtn"
        :class="{ subDetailType, taskbtn: detailType === 'STAGE_LEARNING_REPORT' && subDetailType === 'teacher' }"
        v-if="subDetailType === 'teacher' || (hideBtn && hideBtnRole)"
      >
        <a-button
          :style="{ marginRight: '8px' }"
          type="primary"
          @click="turnOverGroup"
          v-if="detailType === 'TASK_CLASS_ADMIN_JOIN' && formInfoData.upComingTypeValues === 'UpComing'"
        >
          交接入群
        </a-button>
        <a-button
          :style="{ marginRight: '8px' }"
          type="primary"
          @click="createGroup"
          v-if="detailType === 'TASK_CREATE_GROUP' && formInfoData.upComingTypeValues === 'UpComing'"
        >
          建群
        </a-button>
        <!-- <a-button
          :style="{ marginRight: '8px' }"
          type="primary"
          @click="finishMakeUp"
          v-if="detailType === 'TASK_PROMOTE' && formInfoData.upComingTypeValues === 'UpComing'"
        >
          完成
        </a-button> -->
        <a-button
          :style="{ marginRight: '8px' }"
          @click="goMakeUp"
          v-if="detailType === 'TASK_PROMOTE' && formInfoData.upComingTypeValues === 'UpComing'"
        >
          去补升
        </a-button>
        <a-button
          :style="{ marginRight: '8px' }"
          type="primary"
          v-if="detailType === 'TASK_SCHEDULE' && formInfoData.upComingTypeValues === 'UpComing'"
        >
          确认首课时间
        </a-button>
        <a-button
          :style="{ marginRight: '8px' }"
          @click="ToScheduleClasses"
          v-if="
            detailType === 'TASK_SCHEDULE' &&
            formInfoData.upComingTypeValues === 'UpComing' &&
            formInfoData.subjectName === '中文'
          "
        >
          去排课
        </a-button>
        <a-button
          :style="{ marginRight: '8px' }"
          type="primary"
          @click="addRecordModal"
          v-if="
            subDetailType === 'classAdmin' &&
            (detailType === 'TASK_STUDENT_ABSENT' ||
              detailType === 'TASK_CHANGE_TEACHER_VISIT' ||
              detailType === 'TASK_LONG_TASK_OVER_VISIT' ||
              detailType === 'TASK_CLASS_ADMIN_RESIGN_STUDENT' ||
              detailType === 'TASK_NEXT_FOLLOW_RECORD' ||
              detailType === 'TASK_LOSS_REFUND_STUDENT_FIRST_PASS' ||
              detailType === 'TASK_REPEAT_TRACK' ||
              detailType === 'TASK_FIRST_PASS' ||
              detailType === 'TASK_FIRST_FOLLOW_UP' ||
              detailType === 'TASK_FIRST_VISIT' ||
              detailType === 'TASK_MOTH_VISIT' ||
              detailType === 'TASK_MOTH_STUDY_VISIT' ||
              detailType === 'TASK_W2_VISIT' ||
              detailType === 'STAGE_LEARNING_REPORT' ||
              detailType === 'TASK_W3_VISIT' ||
              detailType === 'TASK_RESIGN_STUDENT_OLD_CLASSADMIN' ||
              detailType === 'TASK_CLASS_ADMIN_FIRST_PASS_V2' ||
              detailType === 'TASK_CLASS_ADMIN_FIRST_PASS_FOLLOW_UP_V2' ||
              detailType === 'TASK_TEACHER_ABSENT' ||
              detailType === 'TASK_CLASS_ADMIN_FIRST_PASS' ||
              detailType === 'TASK_SUBSCRIBE_COURSE' ||
              detailType === 'TASK_TRACK_CREATE' ||
              detailType === 'TASK_CERTIFICATE' ||
              (detailType === 'TASK_NOT_RENEW' && formInfoData.userRole === 'QA') ||
              detailType === 'TASK_PROMOTE' ||
              detailType === 'TASK_TERM_VISIT' ||
              detailType === 'TASK_WEEK_VISIT' ||
              detailType === 'REVIEW_QUESTION_REMINDER' ||
              detailType === 'TASK_STUDENT_ACTIVE' ||
              detailType === 'DAYLIGHT_TIME_CHANGED' ||
              detailType === 'TASK_STUDENT_MULTI_CHILD' ||
              detailType === 'LOW_SCORE_TEST' ||
              detailType === 'TEACHER_LATE' ||
              detailType === 'NEW_STUDENT_FOLLOW_UP' ||
              detailType === 'ATTEMPT_RETENTION_ON_CANCELLATION' ||
              detailType === 'TRANSFER_STUDENT_FOLLOW_UP' ||
              detailType === 'TASK_TOPIC_COURSE_SELECTION_FOLLOW_UP' ||
              detailType === 'SUBSCRIPTION_DEDUCTION_FAILED_TASK') &&
            formInfoData.upComingTypeValues === 'UpComing'
          "
        >
          <img :src="ok" alt="" /> 完成跟进
        </a-button>
        <a-button
          :style="{ marginRight: '8px' }"
          @click="lookClassTable"
          v-if="
            (detailType === 'TASK_MOTH_VISIT' ||
              detailType === 'TASK_MOTH_STUDY_VISIT' ||
              detailType === 'TASK_W2_VISIT' ||
              detailType === 'TASK_W3_VISIT') &&
            formInfoData.upComingTypeValues === 'UpComing'
          "
        >
          查看课表
        </a-button>
        <a-button
          :style="{ marginRight: '8px' }"
          type="primary"
          @click="onClassResign(detailType)"
          v-if="
            detailType === 'CHINESE_RESIGN_TAKE_OVER' &&
            formInfoData.upComingTypeValues === 'UpComing' &&
            subDetailType !== 'classAdmin'
          "
        >
          <img :src="ok" alt="" /> 提交
        </a-button>

        <a-button
          :style="{ marginRight: '8px' }"
          type="primary"
          @click="onHandler('complate')"
          v-if="
            (detailType === 'CHINESE_RESIGN_TAKE_OVER' &&
              formInfoData.assigneesList[1].data.finished &&
              formInfoData.upComingTypeValues === 'UpComing') ||
            (formInfoData.upComingTypeValues === 'UpComing' &&
              subDetailType === 'classAdmin' &&
              detailType !== 'STAGE_LEARNING_REPORT' &&
              detailType !== 'TASK_CHANGE_TEACHER_VISIT' &&
              detailType !== 'TASK_LONG_TASK_OVER_VISIT' &&
              detailType !== 'TASK_REPEAT_TRACK' &&
              detailType !== 'TASK_CLASS_ADMIN_RESIGN_STUDENT' &&
              detailType !== 'TASK_NEXT_FOLLOW_RECORD' &&
              detailType !== 'TASK_LOSS_REFUND_STUDENT_FIRST_PASS' &&
              detailType !== 'PROMOTE_EXPIRED_TASK' &&
              detailType !== 'TASK_FIRST_PASS' &&
              detailType !== 'TASK_FIRST_FOLLOW_UP' &&
              detailType !== 'TASK_CREATE_GROUP' &&
              detailType !== 'NEW_STUDENT_FOLLOW_UP' &&
              detailType !== 'TASK_SCHEDULE' &&
              detailType !== 'TASK_PROMOTE' &&
              detailType !== 'TASK_CLASS_ADMIN_FIRST_PASS' &&
              detailType !== 'TASK_CLASS_ADMIN_JOIN' &&
              detailType !== 'TRANSFER_STUDENT_FOLLOW_UP' &&
              detailType !== 'TASK_MOTH_VISIT' &&
              detailType !== 'TASK_MOTH_STUDY_VISIT' &&
              detailType !== 'TASK_W2_VISIT' &&
              detailType !== 'TASK_W3_VISIT' &&
              detailType !== 'TASK_STUDENT_ABSENT' &&
              detailType !== 'TASK_STUDENT_ABSENT' &&
              detailType !== 'TASK_FIRST_VISIT' &&
              detailType !== 'TASK_RESIGN_STUDENT_OLD_CLASSADMIN' &&
              detailType !== 'TASK_CLASS_ADMIN_FIRST_PASS_V2' &&
              detailType !== 'TASK_CLASS_ADMIN_FIRST_PASS_FOLLOW_UP_V2' &&
              detailType !== 'TASK_TEACHER_ABSENT' &&
              detailType !== 'TASK_SUBSCRIBE_COURSE' &&
              detailType !== 'TASK_CERTIFICATE' &&
              detailType !== 'TASK_TRACK_CREATE' &&
              detailType !== 'TASK_TERM_VISIT' &&
              detailType !== 'TASK_WEEK_VISIT' &&
              detailType !== 'REVIEW_QUESTION_REMINDER' &&
              detailType !== 'TASK_NOT_RENEW' &&
              detailType !== 'DAYLIGHT_TIME_CHANGED' &&
              detailType !== 'TASK_STUDENT_MULTI_CHILD' &&
              detailType !== 'TEACHER_LATE' &&
              detailType !== 'TASK_STUDENT_ACTIVE' &&
              detailType !== 'ATTEMPT_RETENTION_ON_CANCELLATION' &&
              detailType !== 'TASK_TOPIC_COURSE_SELECTION_FOLLOW_UP' &&
              detailType !== 'LOW_SCORE_TEST' &&
              detailType !== 'SUBSCRIPTION_DEDUCTION_FAILED_TASK') ||
            (formInfoData.upComingTypeValues === 'UpComing' &&
              detailType === 'TASK_NOT_RENEW' &&
              formInfoData.userRole !== 'QA') ||
            (formInfoData.upComingTypeValues === 'UpComing' &&
              !formInfoData.finished &&
              (detailType === 'TEACHER_NO_ABILITY' ||
                detailType === 'TEACHER_SHIFT_APPEAL' ||
                detailType === 'TEACHER_LEAVE_APPEAL' ||
                detailType === 'TEACHER_QA_APPEAL' ||
                detailType === 'TEACHER_THANKS' ||
                detailType === 'TEACHER_COMPLAINT'))
          "
        >
          <img :src="ok" alt="" /> 完成
        </a-button>
        <a-button
          :style="{ marginRight: '8px' }"
          @click="onHandler('refuse')"
          v-if="
            formInfoData.upComingTypeValues === 'UpComing' &&
            detailType !== 'STAGE_LEARNING_REPORT' &&
            detailType !== 'CHINESE_RESIGN_TAKE_OVER' &&
            detailType !== 'TASK_CHANGE_TEACHER_VISIT' &&
            detailType !== 'TASK_LONG_TASK_OVER_VISIT' &&
            detailType !== 'TASK_CLASS_ADMIN_RESIGN_STUDENT' &&
            detailType !== 'TASK_NEXT_FOLLOW_RECORD' &&
            detailType !== 'TASK_LOSS_REFUND_STUDENT_FIRST_PASS' &&
            detailType !== 'TASK_CERTIFICATE' &&
            detailType !== 'TASK_REPEAT_TRACK' &&
            detailType !== 'PROMOTE_EXPIRED_TASK' &&
            detailType !== 'TASK_CONFIRM_ORDER' &&
            detailType !== 'SUBMIT_ORDER_TASK' &&
            detailType !== 'TASK_FIRST_PASS' &&
            detailType !== 'TASK_FIRST_FOLLOW_UP' &&
            detailType !== 'TASK_CREATE_GROUP' &&
            detailType !== 'TASK_SCHEDULE' &&
            detailType !== 'TASK_PROMOTE' &&
            detailType !== 'TASK_CLASS_ADMIN_FIRST_PASS' &&
            detailType !== 'TASK_CLASS_ADMIN_JOIN' &&
            detailType !== 'TASK_MOTH_VISIT' &&
            detailType !== 'TASK_MOTH_STUDY_VISIT' &&
            detailType !== 'TASK_W2_VISIT' &&
            detailType !== 'TASK_W3_VISIT' &&
            detailType !== 'TASK_FIRST_VISIT' &&
            detailType !== 'TASK_STUDENT_ABSENT' &&
            detailType !== 'TASK_RESIGN_STUDENT_OLD_CLASSADMIN' &&
            detailType !== 'TASK_RESIGN_STUDENT_NEW_CLASSADMIN' &&
            detailType !== 'TASK_TEACHER_ABSENT' &&
            detailType !== 'TASK_SUBSCRIBE_COURSE' &&
            detailType !== 'TASK_TRACK_CREATE' &&
            detailType !== 'TASK_NOT_RENEW' &&
            detailType !== 'TASK_PRIVATE_CLASS_REMINDER' &&
            detailType !== 'TASK_TERM_VISIT' &&
            detailType !== 'TASK_WEEK_VISIT' &&
            detailType !== 'REVIEW_QUESTION_REMINDER' &&
            detailType !== 'TASK_STUDENT_ACTIVE' &&
            detailType !== 'DAYLIGHT_TIME_CHANGED' &&
            detailType !== 'TASK_STUDENT_CU_EXPIRE_DATE_REMINDER' &&
            formInfoData.upComingTypeValues === 'UpComing' &&
            !formInfoData.finished &&
            (detailType === 'TEACHER_SHIFT_APPEAL' ||
              detailType === 'TEACHER_LEAVE_APPEAL' ||
              detailType === 'TEACHER_QA_APPEAL' ||
              detailType === 'TEACHER_THANKS' ||
              detailType === 'TEACHER_COMPLAINT' ||
              detailType === 'SCHEDULE_FEEDBACK')
          "
        >
          <img :src="cancle" alt="" /> 拒绝
        </a-button>
        <a-button
          v-if="
            !formInfoData.shiftBol &&
            detailType !== 'TASK_CREATE_GROUP' &&
            detailType !== 'TASK_SCHEDULE' &&
            detailType !== 'TASK_PROMOTE' &&
            detailType !== 'TASK_CLASS_ADMIN_FIRST_PASS' &&
            detailType !== 'TASK_CLASS_ADMIN_JOIN' &&
            detailType !== 'TASK_MOTH_VISIT' &&
            detailType !== 'TASK_MOTH_STUDY_VISIT' &&
            detailType !== 'TASK_W2_VISIT' &&
            detailType !== 'TASK_W3_VISIT' &&
            detailType !== 'TASK_FIRST_VISIT' &&
            detailType !== 'TASK_WEEK_VISIT' &&
            detailType !== 'REVIEW_QUESTION_REMINDER' &&
            detailType !== 'TASK_STUDENT_ACTIVE' &&
            detailType !== 'SCHEDULE_FEEDBACK'
          "
          :style="{ marginRight: '8px' }"
          @click="onHandler('comment')"
          ><img :src="comment" alt="" />评论</a-button
        >
        <a-button
          :style="{ marginRight: '8px' }"
          v-if="
            !formInfoData.shiftBol &&
            detailType !== 'SCHEDULE_FEEDBACK' &&
            ((subDetailType === 'classAdmin' &&
              detailType !== 'STAGE_LEARNING_REPORT' &&
              detailType !== 'TASK_CREATE_GROUP' &&
              detailType !== 'TASK_SCHEDULE' &&
              detailType !== 'TASK_PROMOTE' &&
              detailType !== 'TASK_CLASS_ADMIN_FIRST_PASS' &&
              detailType !== 'TASK_CLASS_ADMIN_JOIN' &&
              detailType !== 'TASK_MOTH_VISIT' &&
              detailType !== 'TASK_MOTH_STUDY_VISIT' &&
              detailType !== 'TASK_W2_VISIT' &&
              detailType !== 'TASK_W3_VISIT' &&
              detailType !== 'TASK_FIRST_VISIT') ||
              formInfoData.assigneesList[1].data.finished)
          "
          @click="onHandler('CC')"
          ><img :src="copy" alt="" /> 抄送</a-button
        >
        <a-button
          @click="onHandler('turn')"
          v-if="
            formInfoData.upComingTypeValues === 'UpComing' &&
            subDetailType === 'classAdmin' &&
            detailType !== 'TASK_CLASS_ADMIN_RESIGN_STUDENT' &&
            detailType !== 'PROMOTE_EXPIRED_TASK' &&
            detailType !== 'SUBMIT_ORDER_TASK' &&
            detailType !== 'TASK_CREATE_GROUP' &&
            detailType !== 'TASK_SCHEDULE' &&
            detailType !== 'TASK_PROMOTE' &&
            detailType !== 'TASK_CLASS_ADMIN_FIRST_PASS' &&
            detailType !== 'TASK_CLASS_ADMIN_JOIN' &&
            detailType !== 'TASK_MOTH_VISIT' &&
            detailType !== 'TASK_MOTH_STUDY_VISIT' &&
            detailType !== 'TASK_W2_VISIT' &&
            detailType !== 'TASK_W3_VISIT' &&
            detailType !== 'TASK_FIRST_VISIT' &&
            detailType !== 'TASK_STUDENT_ABSENT' &&
            detailType !== 'TASK_RESIGN_STUDENT_OLD_CLASSADMIN' &&
            detailType !== 'TASK_TEACHER_ABSENT' &&
            detailType !== 'SCHEDULE_FEEDBACK'
          "
        >
          <img :src="trans" alt="" /> 转交
        </a-button>
        <a-button
          v-if="
            formInfoData.upComingTypeValues === 'Icreate' &&
            formInfoData.finished &&
            detailType !== 'STAGE_LEARNING_REPORT' &&
            detailType !== 'TAKE_OVER' &&
            detailType !== 'TEACHER_LEAVE' &&
            detailType !== 'RESIGN_TAKE_OVER' &&
            detailType !== 'CHINESE_TEACHER_LEAVE' &&
            detailType !== 'RESIGN_TAKE_OVER_APPLY' &&
            detailType !== 'PROMOTE_EXPIRED_TASK'
          "
          @click="onResubmit"
        >
          <img :src="resent" alt="" /> 再次提交
        </a-button>
        <!-- 学习报告提交 -->
        <a-button
          type="primary"
          @click="onHandler('REPORT')"
          v-if="
            formInfoData.upComingTypeValues === 'UpComing' &&
            detailType === 'STAGE_LEARNING_REPORT' &&
            subDetailType !== 'classAdmin'
          "
          :loading="studyLoading"
          :disabled="studyLoading"
        >
          <img :src="ok" alt="" />提交
        </a-button>
        <a-button
          @click="downloadByBlob(formInfoData.imgUrl)"
          v-if="
            formInfoData.upComingTypeValues !== 'UpComing' &&
            detailType === 'STAGE_LEARNING_REPORT' &&
            subDetailType !== 'classAdmin'
          "
          :disabled="studyBol"
          :loading="studyBol"
        >
          {{ formInfoData.viewReport ? '查看报告' : '下载报告' }}
        </a-button>
      </div>
    </div>
    <a-drawer title="操作" placement="right" width="700" :visible="operatevisible" @close="clearnCurrentView">
      <component
        :formInfoData="formInfoData"
        :classType="formInfoData.classType"
        :courseType="formInfoData.courseType || (formInfoData.relatedClass && formInfoData.relatedClass[0].courseType)"
        :is="currentView"
        :taskType="detailType"
        :adjustScheduleProcess="formInfoData.adjustScheduleProcess"
        :courseScheduleArr="courseScheduleArr"
        :taskTypeName="formInfoData.processDefinitionName"
        :taskStatus="taskStatus"
        :taskDefinitionKey="formInfoData.taskDefinitionKey"
        :baseUrl="baseUrl"
        @onSubmit="onGetformData"
        @clearnCurrentView="clearnCurrentView"
        :studeyData="studeyData"
        :source="subDetailType"
      ></component>
    </a-drawer>
    <a-modal :dialog-style="{ top: '20%' }" width="600px" :visible="isCarousel" :footer="null" @cancel="onCloseImg">
      <div class="carousel-main">
        <a-carousel arrows>
          <div slot="prevArrow" class="custom-slick-arrow" style="left: 10px; z-index: 1">
            <a-icon type="left" />
          </div>
          <div slot="nextArrow" class="custom-slick-arrow" style="right: 10px">
            <a-icon type="right" />
          </div>
          <div class="imgcls" v-for="(item, index) in pictures" :key="index"><img :src="item" alt="" /></div>
        </a-carousel>
      </div>
    </a-modal>
    <!-- 学习报告二次确认 -->
    <a-modal
      :dialog-style="{ top: '40%' }"
      width="374px"
      :visible="studyConfirm"
      :ok-button-props="{ props: { disabled: submitStudy } }"
      @ok="onStudySubmit"
      @cancel="onCloseStudyConfirm"
      class="finishTaskConfirm"
    >
      <div>
        <p class="finishTask-title"><a-icon type="check-circle" theme="filled" />确认学习报告</p>
        <p class="finishTask">确认提交“学生阶段学习报告”吗，报告提交后不可再次修改任务</p>
      </div>
    </a-modal>

    <!--旧的学习报告-->
    <a-modal
      title="报告详情"
      :visible="visibleReport"
      @ok="handleSubmit"
      okText="复制"
      cancelText="关闭"
      width="426px"
      dialogClass="schedule-related"
      @cancel="onCancelReport"
    >
      <div class="modal-height">
        <div
          class="studyReportTemplateTC"
          ref="imageWrapperTC"
          v-if="detailType === 'STAGE_LEARNING_REPORT' && reportData && !formInfoData.showNewReport"
        >
          <StudyReportTemplateOld :studyData="reportData"></StudyReportTemplateOld>
        </div>
        <!-- 生成海报模板 -->

        <div
          class="studyReportTemplate2TC"
          ref="imageWrapperTC"
          v-if="detailType === 'STAGE_LEARNING_REPORT' && reportData && formInfoData.showNewReport"
        >
          <StudyReportTemplate :studyData="reportData"></StudyReportTemplate>
        </div>
      </div>
    </a-modal>
    <div
      class="studyReportTemplate"
      ref="imageWrapper"
      v-if="detailType === 'STAGE_LEARNING_REPORT' && reportData && !formInfoData.showNewReport"
    >
      <StudyReportTemplateOld :studyData="reportData"></StudyReportTemplateOld>
    </div>
    <!-- 生成海报模板 -->

    <div
      class="studyReportTemplate2"
      ref="imageWrapper"
      v-if="detailType === 'STAGE_LEARNING_REPORT' && reportData && formInfoData.showNewReport"
    >
      <StudyReportTemplate :studyData="reportData"></StudyReportTemplate>
    </div>

    <!--生成阶段结业报告-->
    <a-modal
      title="结业证书"
      :visible="visibleCertificate"
      @ok="handleCertificate"
      okText="复制"
      cancelText="关闭"
      :width="formInfoData.subjectName === '中文' ? '890px' : '640px'"
      dialogClass="schedule-related"
      @cancel="onCancelCertificate"
    >
      <div
        :class="formInfoData.subjectName === '中文' ? 'certificateWrapperCNTC' : 'certificateWrapperTC'"
        ref="certificateWrapperTC"
        v-if="detailType === 'TASK_CERTIFICATE' && reportData"
      >
        <CertificateTemplete :studentInfo="reportData"></CertificateTemplete>
      </div>
    </a-modal>
    <div
      :class="formInfoData.subjectName === '中文' ? 'certificateWrapperCN' : 'certificateWrapper'"
      ref="certificateWrapper"
      v-if="detailType === 'TASK_CERTIFICATE' && reportData"
    >
      <CertificateTemplete :studentInfo="reportData"></CertificateTemplete>
    </div>
    <!--学员激活-->
    <a-modal
      width="550px"
      title="完成任务"
      :visible="visibleActive"
      @ok="saveActive"
      @cancel="cancelActive"
      okText="提交"
      cancelText="取消"
    >
      <a-form :form="activeForm" :label-col="{ span: 7 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="冻结/延期结束时间">
          <a-range-picker
            v-decorator="['queryClassDateArr', { rules: [{ required: true, message: '请选择延期/冻结时间!' }] }]"
            format="YYYY-MM-DD"
          />
        </a-form-item>
        <a-form-item label="补充说明">
          <a-textarea placeholder="请输入" :auto-size="{ minRows: 3, maxRows: 5 }" v-decorator="['remark']" />
        </a-form-item>
      </a-form>
    </a-modal>
    <!--班主任主管角色点击不续费-->
    <a-modal
      width="550px"
      title="提示"
      :visible="visibleRenewal"
      @ok="cancelRenewal"
      @cancel="cancelRenewal"
      okText="我知道了"
      cancelText="取消"
    >
      <div v-if="hasRunTask && hasNormalSchedule">
        该学员<span style="color: #43d186"> {{ `${formInfoData.studentName}(${formInfoData.studentCode})` }} </span
        >的班主任<span style="color: #43d186">{{ classAdminName }} </span>还有未处理的待办任务<br />该学员<span
          style="color: #43d186"
        >
          {{ formInfoData.subjectName }}</span
        >还存在待开课课节，请提醒班主任及时处理
      </div>
      <div v-else-if="hasRunTask && !hasNormalSchedule">
        该学员<span style="color: #43d186"> {{ `${formInfoData.studentName}(${formInfoData.studentCode})` }} </span
        >的班主任<span style="color: #43d186">{{ classAdminName }} </span>还有未处理的待办任务，请提醒班主任及时处理
      </div>
      <div v-else-if="hasNormalSchedule && !hasRunTask">
        该学员<span style="color: #43d186"> {{ `${formInfoData.studentName}(${formInfoData.studentCode})` }} </span
        >的<span style="color: #43d186">{{ formInfoData.subjectName }}</span
        >还存在待开课课节，请提醒班主任及时处理
      </div>
    </a-modal>
    <a-modal
      v-model="showHasLossRefund"
      title="操作提示"
      okText="去添加"
      cancelText="关闭"
      @ok="hrefLostRefundStudent"
      @cancel="cancelLostRefundStudent"
    >
      <div>
        请先添加该学员<span style="color: #04cb94">{{
          `${formInfoData.studentName}(${formInfoData.studentCode})`
        }}</span
        >的{{ formInfoData.subjectName }}【流失记录】，才可完成该任务
      </div>
    </a-modal>
  </div>
</template>
<script>
import axios from 'axios';
import Vue from 'vue';

import { Button, Carousel, Form, Modal, Table } from 'ant-design-vue';
import html2canvas from 'html2canvas';
import moment from 'moment';
import storage from 'store';
import cancle from '../assets/backlog_icon_cancle.png';
import comment from '../assets/backlog_icon_comment.png';
import copy from '../assets/backlog_icon_copy.png';
import ok from '../assets/backlog_icon_ok.png';
import resent from '../assets/backlog_icon_resent.png';
import trans from '../assets/backlog_icon_trans.png';
import defaultImg from '../assets/service_pic_head.png';
import AbsenteeismDetail from './AbsenteeismDetail.vue';
import FeedBackDetail from './FeedbackDetail.vue';
import AppointmentTaskDetail from './AppointmentTaskDetail.vue';
import ApproveTypeDetail from './ApproveTypeDetail.vue';
import AskforLeaveDetail from './AskforLeaveDetail.vue';
import CertificateReportClass from './CertificateReportClass.vue';
import CertificateTemplete from './CertificateTemplete.vue';
import ClassAdminDetail from './ClassAdminDetail.vue';
import FamilyOfDollsDetail from './FamilyOfDollsDetail.vue';
import FirstLostStudents from './FirstLostStudents.vue';
import FirstPassDetail from './FirstPassDetail.vue';
import FirstTeacherVisit from './FirstTeacherVisit.vue';
import HandoverTeacher from './HandoverTeacher.vue';
import LeaveAppealDetail from './LeaveAppealDetail.vue';
import MathOrderApproval from './MathOrderApproval.vue';
import NoRenewalDetail from './NoRenewalDetail.vue';
import OrderApproval from './OrderApproval.vue';
import PeriodValidityDetail from './PeriodValidityDetail.vue';
import PrivateLessonsDetail from './PrivateLessonsDetail.vue';
import WinBackStrategy from './WinBackStrategy.vue';
import AdminHandover from './AdminHandover.vue';
import BookClassContest from './BookClassContest.vue';
import MyProcessingRecords from './ProcessingRecords.vue';
import QaAppealDetail from './QaAppealDetail.vue';
import RepetitiveClues from './RepetitiveClues.vue';
import ResignStudent from './ResignStudentDetail.vue';
import ResignTakeOver from './ResignTakeOver.vue';
import ResignationDetail from './ResignationDetail.vue';
import ScheduleDetail from './ScheduleDetail.vue';
import SendOrderDetail from './SendOrderDetail.vue';
import ShiftAppealDetail from './ShiftAppealDetail.vue';
import StudentAbsentDetail from './StudentAbsentDetail.vue';
import StudentFollowUp from './StudentFollowUp.vue';
import StudyReportClass from './StudyReportClass.vue';
import StudyReportTemplate from './StudyReportTemplate.vue';
import StudyReportTemplateOld from './StudyReportTemplateOld.vue';
import TeacherIsLateDetail from './TeacherIsLateDetail.vue';
import TeacherNoAbilityDetail from './TeacherNoAbilityDetail.vue';
import MyTestOperate from './TestOperate.vue';
import ThanksDetail from './ThanksDetail.vue';
import TimeHandoverDetail from './TimeHandoverDetail.vue';
import TimezoneChange from './TimezoneChange.vue';
import UpgradeHandoverDetail from './UpgradeHandoverDetail.vue';
import LowerTestDetail from './LowerTestDetail.vue';
import SubscriptionDeductionFailedDetail from './SubscriptionDeductionFailedDetail.vue';
import TaskClassAdminFirstPassV2 from './TaskClassAdminFirstPassV2.vue';

Vue.use(Button);
Vue.use(Modal);
Vue.use(Carousel);
Vue.use(Form);
Vue.use(Table);

const token = storage.get('Access-Token');

export default {
  components: {
    FirstPassDetail,
    PeriodValidityDetail,
    FirstLostStudents,
    StudentFollowUp,
    AppointmentTaskDetail,
    SendOrderDetail,
    MyTestOperate,
    MyProcessingRecords,
    ScheduleDetail,
    AskforLeaveDetail,
    UpgradeHandoverDetail,
    TimeHandoverDetail,
    ResignationDetail,
    TeacherNoAbilityDetail,
    StudyReportClass,
    FirstTeacherVisit,
    ResignTakeOver,
    RepetitiveClues,
    ResignStudent,
    CertificateReportClass,
    CertificateTemplete,
    OrderApproval,
    MathOrderApproval,
    ApproveTypeDetail,
    ClassAdminDetail,
    StudentAbsentDetail,
    StudyReportTemplate,
    StudyReportTemplateOld,
    HandoverTeacher,
    AbsenteeismDetail,
    PrivateLessonsDetail,
    WinBackStrategy,
    AdminHandover,
    BookClassContest,
    NoRenewalDetail,
    TimezoneChange,
    ShiftAppealDetail,
    QaAppealDetail,
    LeaveAppealDetail,
    ThanksDetail,
    FamilyOfDollsDetail,
    TeacherIsLateDetail,
    FeedBackDetail,
    LowerTestDetail,
    SubscriptionDeductionFailedDetail,
    TaskClassAdminFirstPassV2,
  },

  props: {
    hideBtnRole: {
      // 当前节点处理人等于当前登录用户才显示
      type: Boolean,
      default: false,
    },
    hideBtn: {
      // 关联任务的详情不需要操作按钮
      type: Boolean,
      default: true,
    },
    subDetailType: {
      // 区分老师还是班主任的学习海报详情
      // 区分老师还是班主任评论上传组件展示
      type: String,
    },
    formInfoData: {
      type: Object,
    },
    baseUrl: {
      type: String,
    },
    operatevisibleWrap: {
      type: Boolean,
    },
    detailType: {
      // 详情类型
      type: String,
    },
    courseScheduleArr: {
      type: Array,
    },
  },
  emits: ['onGetformData'],
  data() {
    return {
      showHasLossRefund: false,
      classAdminName: '',
      hasRunTask: false,
      hasNormalSchedule: false,
      visibleRenewal: false,
      visibleActive: false,
      activeForm: this.$form.createForm(this),
      token,
      certificateWidth: '426px',
      visibleCertificate: false,
      visibleReport: false,
      defaultImg,
      comment,
      cancle,
      copy,
      resent,
      trans,
      ok,
      operatevisible: false,
      courseInfo: null,
      currentView: null,
      assigneesList: null,
      taskStatus: '',
      isCarousel: false,
      pictures: [],
      showPoster: false,
      resignData: null,
      reportData: null,
      isCheak: true,
      studeyData: null,
      formDataType: null,
      newReportFlag: true, // 区分是展示新报告还是旧报告
      studyBol: false,
      studyLoading: false,
      studyConfirm: false,
      submitStudy: false,
      isResignData: null,
    };
  },
  watch: {
    operatevisibleWrap(val) {
      this.clearnCurrentView();
      this.$emit('initOperateVisible', val);
    },
  },
  mounted() {
    console.log(this.formInfoData);
  },

  methods: {
    cancelLostRefundStudent() {
      this.showHasLossRefund = false;
    },
    cancelRenewal() {
      this.visibleRenewal = false;
    },
    hrefLostRefundStudent() {
      const routeUrl = this.$router.resolve({
        path: '/administration/lostRefundStudent',
      });
      window.open(routeUrl.href, '_blank');
    },
    saveActive(e) {
      e.preventDefault();
      this.activeForm.validateFields((err, values) => {
        if (!err) {
          const params = {
            message: values.remark,
            freezeFromDate: values.queryClassDateArr[0].format('YYYY-MM-DD'), // 冻结开始时间
            freezeToDate: values.queryClassDateArr[1].format('YYYY-MM-DD'), // 冻结结束时间
          };
          this.$emit('onGetformData', { taskType: 'complate', selfActive: true, formData: params });
        }
      });
    },
    cancelActive() {
      this.visibleActive = false;
    },
    onCancelCertificate() {
      this.visibleCertificate = false;
    },
    handleCertificate() {
      this.$nextTick(() => {
        html2canvas(this.$refs.certificateWrapperTC).then((canvas) => {
          const dataURL = canvas.toDataURL('image/png');
          const fileName = `${this.reportData.name}的阶段结业证书M2-D-${this.$moment(new Date()).format(
            'YYYYMMDDHHmmss',
          )}.png`;
          this.downloadByBlobClass(dataURL, fileName);
        });
      });
    },
    handleSubmit() {
      this.$nextTick(() => {
        html2canvas(this.$refs.imageWrapperTC).then((canvas) => {
          const dataURL = canvas.toDataURL('image/png');
          const fileName = `${
            this.formInfoData.studentArray[0].name
          }的阶段学习报告${this.formInfoData.progress.substring(
            0,
            this.formInfoData.progress.indexOf('-'),
          )}-${this.$moment(new Date()).format('YYYYMMDDHHmmss')}.png`;
          this.downloadByBlobClass(dataURL, fileName);
        });
      });
    },

    onCancelReport() {
      this.visibleReport = false;
    },
    playCourse(platform) {
      this.$emit('playCourseDetail', platform);
    },
    playClass(platform) {
      this.$emit('playClassDetail', platform);
    },

    openConTaskDeatil(detailObj) {
      this.$emit('openConTaskDeatil', detailObj);
    },
    closeloadPoster() {
      this.showPoster = false;
    },
    downloadImage(url, name) {
      this.studyBol = false;
      const link = document.createElement('a');
      link.href = url;
      link.download = name;
      link.click();
    },
    // 班主任下载学习海报
    downloadByBlobClass(url) {
      const image = new Image();
      image.setAttribute('crossOrigin', 'anonymous');
      image.src = url;
      image.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0, image.width, image.height);
        canvas.toBlob(async (blob) => {
          const data = [
            new ClipboardItem({
              [blob.type]: blob,
            }),
          ];
          await navigator.clipboard.write(data);
          this.$message.success('复制成功');
        });
        // canvas.toBlob((blob) => {
        //   const imgUrl = URL.createObjectURL(blob);
        //   this.downloadImage(imgUrl, name);
        //   URL.revokeObjectURL(imgUrl);
        //   setTimeout(() => {
        //     this.$message.success('下载成功');
        //   }, 1000);
        // });
      };
    },
    downloadByBlob() {
      this.studyBol = true;
      this.reportData = {
        score: this.formInfoData.score,
        name: this.formInfoData.studentArray ? this.formInfoData.studentArray[0].name : '',
        progress: `${this.formInfoData.progress.split('-')[0]}-${this.formInfoData.progress.split('-')[1]}`,
        teacherName: this.formInfoData.teacherName,
        createTime: this.formInfoData.dueDate.substring(0, 10),
        listeningLevel: this.formInfoData.listeningLevel,
        readingLevel: this.formInfoData.readingLevel,
        speakingLevel: this.formInfoData.speakingLevel,
        writingLevel: this.formInfoData.writingLevel,
        remake: this.formInfoData.remake,
        classSection: this.formInfoData.classSection,
      };

      // const image = new Image();
      const studyName = `${this.formInfoData.studentArray[0].name}阶段学习报告${
        this.formInfoData.progress.split('-')[0]
      }-${this.formInfoData.progress.split('-')[1]}.png`;
      this.$nextTick(() => {
        html2canvas(this.$refs.imageWrapper).then((canvas) => {
          const dataURL = canvas.toDataURL('image/png');
          this.downloadImage(dataURL, studyName);
        });
      });
      // image.setAttribute('crossOrigin', 'anonymous');
      // image.src = url;
      // image.onload = () => {
      //   const canvas = document.createElement('canvas');
      //   canvas.width = image.width;
      //   canvas.height = image.height;
      //   const ctx = canvas.getContext('2d');
      //   ctx.drawImage(image, 0, 0, image.width, image.height);
      //   canvas.toBlob((blob) => {
      //     const imgUrl = URL.createObjectURL(blob);
      //     this.downloadImage(imgUrl, studyName);
      //     URL.revokeObjectURL(imgUrl);
      //   });
      // };
    },
    moment,
    handleConfirmAddress() {
      this.$emit('onGetformData', { taskType: 'ConfirmAddress' });
    },
    turnOverGroup() {
      this.$emit('onGetformData', { taskType: 'turnOverGroup' });
    },
    lookClassTable() {
      this.$emit('onGetformData', { taskType: 'lookClassTable' });
    },
    // 完成补升
    finishMakeUp() {
      this.$emit('onGetformData', { taskType: 'finishMakeUp' });
    },
    // 去补升
    goMakeUp() {
      this.$emit('onGetformData', { taskType: 'goMakeUp' });
    },

    // 去排课
    ToScheduleClasses() {
      this.$emit('onGetformData', { taskType: 'ToScheduleClasses' });
    },
    // 建群
    createGroup() {
      this.$emit('onGetformData', { taskType: 'createGroup' });
    },
    // 添加跟进记录
    addRecordModal() {
      this.$emit('onGetformData', { taskType: 'addRecord' });
    },
    // 新班主任交接学生查看学生信息
    lookStudentInfo() {
      this.$emit('onGetformData', { taskType: 'lookStudentInfo' });
    },
    onClassResign(data) {
      // this.$emit('onGetformData', { taskType: data, formData: this.resignData });

      this.isResignData.validateFields((err, values) => {
        if (!err) {
          const formData = values;
          const initTime = this.$moment(formData.takeOverDate).format('YYYY-MM-DD HH:mm:ss');
          formData.takeOverDate = initTime;
          const resignData = { ...formData, businessKey: 'resign-take-over-form' };
          this.$emit('onGetformData', { taskType: data, formData: resignData });
        }
      });
    },
    // 获取班级离职交接数据
    onClassResignation(formTye) {
      this.isResignData = formTye;
    },
    // 获取学习报告数据
    onStudyReport(formTye) {
      this.resignData = null;
      this.formDataType = formTye;
      const getFieldsValue = formTye.getFieldsValue();
      const a = Object.entries(getFieldsValue).every((item) => item[1] !== undefined && item[1]);
      if (a) {
        formTye.validateFields((err, values) => {
          if (!err) {
            const formData = values;
            this.resignData = { ...formData };
          }
        });
      }
    },
    onPictures(datas) {
      this.pictures = [];
      this.isCarousel = true;
      this.pictures = datas;
    },
    onCloseImg() {
      this.isCarousel = false;
    },
    onGetStudentId(data) {
      this.$emit('onGetStudentId', data);
    },
    onHrefClue(data) {
      this.$emit('onHrefClue', data);
    },
    onResubmit() {
      this.$emit('onGetformData', { taskType: 'reSubmit', formData: this.formInfoData });
    },
    onOrder(data) {
      this.$emit('onOrder', { formInfoData: this.formInfoData, studnetData: data });
    },
    onMakeUp() {
      this.$emit('onMakeUp', this.formInfoData);
    },
    courseScheduleInfo() {
      this.$emit('courseScheduleInfo');
    },
    onStudySubmit() {
      let studyData = null;
      studyData = { ...this.studeyData };
      this.submitStudy = true;
      const FeedbackData = {
        taskType: this.taskStatus,
        formData: studyData,
      };
      this.onGetformData(FeedbackData);
    },
    onCloseStudyConfirm() {
      this.studyConfirm = false;
    },
    onGetformData(data) {
      this.$emit('onGetformData', data);
    },
    sendStudyReport(data) {
      this.reportData = data;
      this.$nextTick(() => {
        html2canvas(this.$refs.imageWrapper).then((canvas) => {
          const dataURL = canvas.toDataURL('image/png');

          const params = {
            studentId: this.formInfoData.studentArray[0].id,
            imgData: dataURL,
          };
          this.$emit('sendStudyReport', params);
        });
      });
    },
    sendCertificateReport(data) {
      this.reportData = data;
      this.$nextTick(() => {
        html2canvas(this.$refs.certificateWrapper).then((canvas) => {
          const dataURL = canvas.toDataURL('image/png');
          const params = {
            studentId: this.formInfoData.reportData.studentId,
            imgData: dataURL,
          };
          this.$emit('sendStudyReport', params);
        });
      });
    },
    // 生成结业报告
    onCertificateReport(data) {
      this.reportData = data;
      this.visibleCertificate = true;
      // this.$nextTick(() => {
      //   html2canvas(this.$refs.certificateWrapper).then((canvas) => {
      //     const dataURL = canvas.toDataURL('image/png');
      //     const fileName = `${data.name}的阶段结业证书M2-D-${this.$moment(new Date()).format('YYYYMMDDHHmmss')}.png`;
      //     this.downloadByBlobClass(dataURL, fileName);
      //   });
      // });
    },
    // 班主任生成学生报告
    onClassReport(data) {
      this.visibleReport = true;
      this.reportData = data;
      // this.$nextTick(() => {
      //   html2canvas(this.$refs.imageWrapper).then((canvas) => {
      //     const dataURL = canvas.toDataURL('image/png');
      //     const fileName = `${
      //       this.formInfoData.studentArray[0].name
      //     }的阶段学习报告${this.formInfoData.progress.substring(
      //       0,
      //       this.formInfoData.progress.indexOf('-'),
      //     )}-${this.$moment(new Date()).format('YYYYMMDDHHmmss')}.png`;
      //     this.downloadByBlobClass(dataURL, fileName);
      //   });
      // });
    },
    onHandler(value) {
      console.log('完成-click', value);

      this.studeyData = null;
      if (value === 'REPORT') {
        this.formDataType.validateFields((err) => {
          this.studyLoading = true;
          if (!err) {
            // const formData = values;
            // this.resignData = { ...formData };
            this.reportData = {
              score: this.resignData.score,
              name: this.formInfoData.studentArray ? this.formInfoData.studentArray[0].name : '',
              progress: `${this.formInfoData.progress.split('-')[0]}-${this.formInfoData.progress.split('-')[1]}`,
              teacherName: this.formInfoData.teacherName,
              createTime: this.formInfoData.dueDate.substring(0, 10),
              listeningLevel: this.resignData.listeningLevel,
              readingLevel: this.resignData.readingLevel,
              speakingLevel: this.resignData.speakingLevel,
              writingLevel: this.resignData.writingLevel,
              remake: this.resignData.remake,
            };
            this.studeyData = { ...this.resignData, imgData: '' };
            this.$nextTick(() => {
              html2canvas(this.$refs.imageWrapper).then(() => {
                // const dataURL = canvas.toDataURL('image/png');
                this.studeyData = { ...this.resignData, imgData: '' };
                this.taskStatus = value;
                // this.currentView = 'MyTestOperate';
                this.studyConfirm = true;
                this.operatevisible = false;
                this.studyLoading = false;
              });
            });
          }
        });
      } else {
        // 学员激活要单独判断完成
        if (this.detailType === 'TASK_STUDENT_ACTIVE' && value === 'complate') {
          axios
            .get(
              `${process.env.VUE_APP_API_IM_URL}/api/task/student_active/reminder`,
              { params: { studentId: this.formInfoData.studentArray[0].studentId } },
              { headers: { Authorization: `Bearer ${this.token}` } },
            )
            .then((res) => {
              if (res.data.content) {
                this.visibleActive = true;
              } else {
                this.taskStatus = value;
                this.currentView = 'MyTestOperate';
                this.operatevisible = true;
              }
            });
        } else if (this.detailType === 'TASK_NOT_RENEW' && value === 'complate') {
          // 学员不续费,班主任主管单独判断

          axios
            .get(
              `${process.env.VUE_APP_API_IM_URL}/api/task/not_renew/validate/${this.formInfoData.processInstanceId}`,
              { headers: { Authorization: `Bearer ${this.token}` } },
            )
            .then((res) => {
              console.log(res);
              const data = res.data.content;
              if (data.notify) {
                // hasLossRefund = false 话，就要提示弹窗
                if (data.hasLossRefund) {
                  this.visibleRenewal = true;
                  // 是否有代办任务，true有 false没有
                  this.hasRunTask = data.hasRunTask;
                  // 是否有待开课课节
                  this.hasNormalSchedule = data.hasNormalSchedule;
                  this.classAdminName = data.classAdminName;
                  this.hasLossRefund = data.hasLossRefund;
                } else {
                  this.showHasLossRefund = true;
                }
              } else {
                this.taskStatus = value;
                this.currentView = 'MyTestOperate';
                this.operatevisible = true;
              }
            });
        } else {
          this.taskStatus = value;
          this.currentView = 'MyTestOperate';
          this.operatevisible = true;
        }
      }
    },
    clearnCurrentView() {
      this.operatevisible = false;
      this.currentView = null;
    },
  },
};
</script>

<style lang="less">
.finishTaskConfirm {
  .finishTask-title {
    font-size: 16px;
    font-weight: 600;
    color: #333333;
    line-height: 24px;
    margin-bottom: 24px;
    text-align: center;
    i {
      line-height: 24px;
      font-size: 18px;
      color: #009cff;
      margin-right: 10px;
    }
  }
  .finishTask {
    font-size: 14px;
    line-height: 22px;
    color: #1d2129;
  }
  .ant-modal-content {
    border-radius: 4px;
  }
  .ant-modal-footer {
    border-top: none;
    div {
      display: flex;
      justify-content: center;
    }
    .ant-btn-primary {
      background-color: #29b4ff;
    }
  }
}

.poster-dialog .ant-modal-content {
  border-radius: 30px !important;
}
.poster-dialog .ant-modal-body {
  max-height: 800px;
  overflow-y: auto;
}
</style>
<style scoped>
.task-info {
  height: calc(100vh - 130px);
}
.task-info .task-type {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
  margin-bottom: 20px;
}
.task-info .task-type span {
  padding: 2px 10px;
  background-color: #f7f7f7;
  border-radius: 6px;
  margin-right: 10px;
}
.taskbtn {
  width: 800px !important;
  left: 50%;
  bottom: 0px !important;
  margin-left: -355px;
}
.taskFormBtn {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 700px;
  border-top: 1px solid #e9e9e9;
  background-color: #fff;
  padding: 10px 16px;
  background: '#fff';
  text-align: right;
  z-index: 1;
}
.taskFormBtn img {
  margin: 0 10px 0 0;
}
.ant-btn-primary {
  background-color: #009cff;
}
.processing-records {
  /* height: calc(100vh - 520px);
  overflow-y: auto; */
  border-top: 1px solid #e9e9e9;
  padding: 20px 0 0;
  padding-bottom: 50px;
}
.handler-records {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}
.handler-records p {
  margin: 0;
}
.handler-img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.line {
  width: 0;
  height: 100%;
  border-left: 1px solid #eee;
  margin: auto;
}
.handlering {
  border: 1px solid #009cff;
}
.handler-info {
  width: 93%;
  margin-left: 10px;
}
.handler-teacher {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: #999;
  margin-bottom: 10px;
}
.teacher-list {
  width: 80%;
  display: flex;
  justify-content: flex-start;
}
.transfer {
  margin: 0 10px;
  color: #999 !important;
}
.teacher-list p {
  display: inline-block;
  max-width: 57%;
  line-height: 22px;
  color: #333;
}
.teacher-list p span {
  color: #009cff;
}
.teacher-name {
  max-width: 94%;
}
.handler-status {
  font-size: 14px;
  color: #999;
  padding: 2px 10px;
  border-radius: 6px;
}
.title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 20px;
}
.title::before {
  content: '';
  display: block;
  float: left;
  margin: 4px 12px 0 0;
  width: 4px;
  height: 20px;
  background-color: #009cff;
  border-radius: 0px 6px 6px 0px;
}
.carousel-main {
  width: 550px;
  height: 340px;
  background: #fff;
  border-radius: 20px;
}
.carousel-main .imgcls {
  width: 550px;
  height: 340px;
}
.carousel-main .imgcls img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.carousel-main .ant-carousel .slick-slide {
  text-align: center;
  height: 340px;
}
.carousel-main .ant-carousel .custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #000;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
}
.carousel-main .ant-carousel .custom-slick-arrow:before {
  display: none;
}
.carousel-main .ant-carousel .custom-slick-arrow:hover {
  opacity: 0.5;
}
.poster-wrap {
  width: 455px;
}
.poster-btn {
  width: 150px;
  height: 46px;

  border-radius: 10px;
  cursor: pointer;
  border: none;
}
.downloadBtn {
  background: #009cff;
  margin-left: 35px;
  color: #fff;
}
.certificateWrapper {
  position: relative;
  width: 595px;
  height: 842px;
  right: -1000000px;
  overflow: hidden;
}
.certificateWrapperCN {
  position: relative;
  width: 842px;
  height: 595px;
  right: -1000000px;
  overflow: hidden;
}
.certificateWrapperTC {
  position: relative;
  width: 595px;
  height: 842px;
  right: 0px;
}
.certificateWrapperCNTC {
  position: relative;
  width: 842px;
  height: 595px;
  right: 0px;
}
.classAdmin .ant-btn-primary {
  background-color: #43d186;
}

.classAdmin .title::before {
  background-color: #43d186;
}
.classAdmin .handler-one .handlering {
  border: 1px solid #00ff40;
}
.studyReportTemplateTC {
  position: relative;
  z-index: 23;
  right: 0px;
  width: 375px;
  background: transparent;
}
.studyReportTemplate2TC {
  position: relative;
  z-index: 23;
  right: 0px;
  width: 375px;
  background: transparent;
}
.studyReportTemplate {
  position: relative;
  z-index: 23;
  right: 0px;
  width: 375px;
  background: transparent;
}
.studyReportTemplate2 {
  position: relative;
  z-index: 23;
  right: 0px;
  width: 375px;
  background: transparent;
}
.modal-height {
  height: 508px;
  overflow-y: auto;
}
.studyReportTemplate {
  position: fixed;
  z-index: 23;
  right: -100000px;
  width: 375px;
  background: transparent;
}
.studyReportTemplate2 {
  position: fixed;
  z-index: 23;
  right: -100000px;
  width: 375px;
  background: transparent;
}
</style>
