import { render, staticRenderFns } from "./TaskClassAdminFirstPassV2.vue?vue&type=template&id=94c0079a&scoped=true&"
import script from "./TaskClassAdminFirstPassV2.vue?vue&type=script&lang=js&"
export * from "./TaskClassAdminFirstPassV2.vue?vue&type=script&lang=js&"
import style0 from "./TaskClassAdminFirstPassV2.vue?vue&type=style&index=0&id=94c0079a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "94c0079a",
  null
  
)

export default component.exports