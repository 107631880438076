<template>
  <div>
    <div class="task-form">
      <p class="form-name">
        <a-tooltip :overlayStyle="{ maxWidth: '500px' }">
          <template slot="title">
            <div class="task-explainTitleTC">
              <span class="task-titleTxt">任务说明</span>
            </div>
            【完成方式】电话/文字沟通<br />
            【完成时效】7天<br />
            【跟进内容】联系家长确认是否继续订阅，协助完成下次扣款<br />
            【注意事项】<br />
            ①及时与家长联系，询问支付失败的原因，提醒更新支付方式<br />
            ②跟进记录中的每一项均需沟通完毕才可完成任务，不得在未沟通的情况下点击完成任务
          </template>
          <i class="explainTC"></i>
        </a-tooltip>
        {{ formInfoData.question }}
        <span class="pending-status" v-if="!formInfoData.finished">待处理</span>
        <span class="success-status" v-if="formInfoData.finished && !formInfoData.deleted">已完成</span>
        <span class="refuse-status" v-if="formInfoData.finished && formInfoData.deleted">已拒绝</span>
      </p>
      <div class="form-teacher">
        任务生成于
        <span>{{ $moment(formInfoData.startTime).utcOffset(8).format('YYYY年MM月DD日 HH:mm') }}</span>
        | 最迟解决时间
        <span
          v-if="formInfoData.endTime"
          :class="{ dueDate: new Date(formInfoData.endTime).getTime() > new Date(formInfoData.dueDate).getTime() }"
          >{{ $moment(formInfoData.dueDate).format('YYYY年MM月DD日 HH:mm')
          }}{{
            new Date(formInfoData.endTime).getTime() > new Date(formInfoData.dueDate).getTime() ? '(逾期)' : ''
          }}</span
        >
        <span v-else :class="{ dueDate: new Date().getTime() > new Date(formInfoData.dueDate).getTime() }"
          >{{ $moment(formInfoData.dueDate).format('YYYY年MM月DD日 HH:mm')
          }}{{ new Date().getTime() > new Date(formInfoData.dueDate).getTime() ? '(逾期)' : '' }}</span
        >
      </div>

      <div class="form-desc form-desc1">
        <span>订单编号：</span>
        <p>{{ formInfoData.orderNumber }}</p>
      </div>
      <div class="form-desc form-desc1">
        <span>扣款时间：</span>
        <p>{{ formInfoData.deductionTime }}</p>
      </div>
    </div>
    <RelatedStudents :formInfoData="formInfoData" @onGetStudentId="onGetStudentId" />
  </div>
</template>

<script>
import { openNewTab } from '../../../../apps/cp-class-advisor-center/src/utils/util';

import defaultImg from '../assets/service_pic_head.png';
import RelatedStudents from './RelatedStudents.vue';

export default {
  components: { RelatedStudents },
  props: {
    formInfoData: {
      type: Object,
    },
    detailType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      cursor: 'cursor',
      defaultImg,
      isCarousel: false,
      pictures: [],
      visibleClass: true,
      toggleTxt: '收起',
      form: this.$form.createForm(this),
      isRequired: false,
      CategoryEnum: {
        EXPECT: '学习期望',
        FEED_BACK: '教学反馈',
        OPERATION: '排课操作',
        FAULT: '系统故障',
      },
    };
  },
  mounted() {
    console.log(this.formInfoData);
  },
  methods: {
    goToReplay() {
      openNewTab(`/lessonReplay?courseScheduleId=${this.formInfoData.courseScheduleId}`);
    },
    onGetStudentId(data) {
      this.$emit('onGetStudentId', data);
    },
  },
};
</script>

<style lang="less" scoped>
.cursor {
  cursor: pointer;
  color: #009cff !important;
}
.classAdmin .cursor {
  cursor: pointer;
  color: #43d186 !important;
}
.title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 20px;
}
.title::before {
  content: '';
  display: block;
  float: left;
  margin: 4px 12px 0 0;
  width: 4px;
  height: 20px;
  background-color: #009cff;
  border-radius: 0px 6px 6px 0px;
}
.task-form {
  border-bottom: 1px solid #eee;
}
.form-name {
  font-size: 20px;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
.form-name span {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 2px 10px;
  border-radius: 6px;
  margin: 0 10px;
}
.pending-status {
  color: #ffbf00;
  background-color: rgba(255, 191, 0, 0.09);
}
.success-status {
  color: #00cf64;
  background-color: rgba(0, 207, 100, 0.09);
}
.refuse-status {
  color: #ff5353;
  background-color: rgba(255, 83, 83, 0.09);
}
.form-teacher {
  font-size: 16px;
  font-weight: 400;
  color: #999;
  line-height: 22px;
  margin-bottom: 15px;
}
.form-teacher .dueDate {
  color: #ff5454;
}
.form-teacher span {
  color: #333;
}
.form-teacher img {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 10px;
}
.form-des {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  margin-bottom: 15px;
}
.form-imgs {
  margin-bottom: 20px;
}
.form-imgs img {
  width: 80px;
  height: 80px;
  border-radius: 4px;
  margin-right: 10px;
}
.courseInfo {
  padding: 20px 0;
}
.course-info {
  padding: 20px 16px;
  background: linear-gradient(180deg, #ecf7ff 0%, #f7fcff 100%);
  border-radius: 20px;
  margin-bottom: 20px;
}
.course-name {
  font-size: 16px;
  font-weight: 600;
  color: #009cff;
  line-height: 22px;
  margin-bottom: 10px;
}
.course-info a:hover {
  text-decoration: underline;
}
.course-type {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 18px;
  margin-top: 10px;
}
.student-item {
  display: flex;
  justify-content: flex-start;
}
.student-item p {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.student-item img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 7px;
}
.student-item .student-name {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  line-height: 22px;
}
.form-desc {
  color: #333;
  font-size: 16px;
}
.form-desc1 {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 6px;
  /deep/.content {
    flex: 1;
    display: flex;
    gap: 8px;
    .el-image {
      width: 72px;
      height: 72px;
    }
  }
}
.form-desc1 p {
  max-width: 80%;
}
.form-desc2 {
  margin-bottom: 20px;
  padding-left: 8px;
}
.red-icon {
  color: #ff5353;
}
.form-label {
  width: 85px;
}
.form-reason {
  flex: 1;
}
.title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 20px;
}
.title::before {
  content: '';
  display: block;
  float: left;
  margin: 4px 12px 0 0;
  width: 4px;
  height: 20px;
  background-color: #009cff;
  border-radius: 0px 6px 6px 0px;
}
.concat-wrap {
  padding-top: 20px;
  margin-bottom: 20px;
}
.concat-con {
  border: 1px solid #eeeeee;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  padding: 10px;
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
}
.concat-item {
  margin-bottom: 5px;
}
.concat-label {
  color: #666;
  font-size: 13px;
  border-left: 1px solid #dddddd;
  padding: 0 10px;
  display: inline-block;
  &:first-child {
    padding: 0 10px 0 0;
    border-left: 0;
  }
}
.bot-border {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px;
}

.class-item {
  font-size: 14px;
  margin-bottom: 4px;
}
.student-item {
  display: flex;
  justify-content: flex-start;
  margin-right: 20px;
}
.student-item p {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.student-item img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 7px;
}
.student-item .student-name {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  line-height: 22px;
}
.student-wrap {
  display: flex;
  margin-bottom: 22px;
}
.classAdmin {
  .title::before {
    background-color: #43d186;
  }
  .course-name {
    color: #43d186;
  }
}
.explainTC {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('../assets/explain.png') no-repeat center;
  background-size: 100%;
  cursor: pointer;
  margin-right: 5px;
}
.task-explainTitleTC {
  padding-bottom: 5px;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.task-titleTxt {
  font-size: 18px;
  font-weight: 600;
}
.task-explain {
  color: #2eaaf9;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}
</style>
