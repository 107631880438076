<template>
  <div :class="subDetailType">
    <div class="task-form">
      <p class="form-name">
        <a-tooltip
          :overlayStyle="{ maxWidth: '500px' }"
          v-if="formInfoData.subDetailType === 'classAdmin' && detailType === 'CHINESE_RESIGN_TAKE_OVER'"
        >
          <template slot="title">
            <div class="task-explainTitleTC">
              <span class="task-explain" @click="lookWord">查看文档说明</span>
            </div>
          </template>
          <i
            class="explainTC"
            v-if="formInfoData.subDetailType === 'classAdmin' && detailType === 'CHINESE_RESIGN_TAKE_OVER'"
          ></i>
        </a-tooltip>

        {{ formInfoData.question }}
        <span class="pending-status" v-if="!formInfoData.finished">待处理</span>
        <span class="success-status" v-if="formInfoData.finished && !formInfoData.deleted">已完成</span>
        <span class="refuse-status" v-if="formInfoData.finished && formInfoData.deleted">已拒绝</span>
      </p>
      <div class="form-teacher">
        <img :src="defaultImg" alt="" />
        <span
          >{{ formInfoData.startUserName }}（<span v-if="formInfoData.userRole"> {{ formInfoData.userRole }}）</span>
        </span>
        <span v-if="formInfoData.startUserName !== '系统自动生成'">提交于</span>
        <span>{{ $moment(formInfoData.startTime).utcOffset(8).format('YYYY年MM月DD日 HH:mm') }}</span>
        | 最迟解决时间
        <span
          v-if="formInfoData.endTime"
          :class="{ dueDate: new Date(formInfoData.endTime).getTime() > new Date(formInfoData.dueDate).getTime() }"
          >{{ $moment(formInfoData.dueDate).format('YYYY年MM月DD日 HH:mm')
          }}{{
            new Date(formInfoData.endTime).getTime() > new Date(formInfoData.dueDate).getTime() ? '(逾期)' : ''
          }}</span
        >
        <span v-else :class="{ dueDate: new Date().getTime() > new Date(formInfoData.dueDate).getTime() }"
          >{{ $moment(formInfoData.dueDate).format('YYYY年MM月DD日 HH:mm')
          }}{{ new Date().getTime() > new Date(formInfoData.dueDate).getTime() ? '(逾期)' : '' }}</span
        >
      </div>
      <div class="form-desc form-desc1" v-if="formInfoData.teacherName">
        <span class="red-icon">*</span>
        <span>离职发起人(老师)：</span>
        <p>{{ formInfoData.teacherName || formInfoData.startUserName }}</p>
      </div>
      <div v-if="formInfoData.subDetailType === 'classAdmin' || formInfoData.upComingTypeValues !== 'UpComing'">
        <div class="form-desc form-desc1">
          <span class="red-icon">*</span>
          <span>最后工作时间（北京）：</span>
          <!-- <span>{{ formInfoData.endTime ? '最后可上课时间：' : '离职时间（北京时间）：' }}</span> -->
          <p>
            {{ formInfoData.takeOverDate ? $moment(formInfoData.takeOverDate).format('YYYY-MM-DD HH:mm:ss') : '-' }}
          </p>
        </div>
        <template>
          <div class="form-desc form-desc1">
            <span class="red-icon">*</span>
            <span class="form-label">对外话术</span>
          </div>
          <div class="form-desc form-desc2">
            老师因个人原因无法继续授课，为保障课堂质量和学习效果，特此交接班级，感谢您的配合。
          </div>
        </template>
        <template v-if="formInfoData.turnManualReason">
          <div class="form-desc form-desc1">
            <span class="red-icon">*</span>
            <span>转人工原因</span>
          </div>
          <div class="form-desc form-desc2">
            {{ turnManualReasonTextMap[formInfoData.turnManualReason] }}
          </div>
        </template>

        <!-- <div class="form-desc form-desc1" v-if="formInfoData.reasonable">
          <span class="red-icon">*</span>
          <span>交接合规：</span>
          <p>{{ formInfoData.reasonable }}</p>
        </div> -->
        <!-- <div class="form-desc form-desc1">
          <span class="red-icon">*</span>
          <span>离职性质：</span>
          <p>{{ formInfoData.workType }}</p>
        </div> -->
        <!-- <div class="form-desc form-desc1">
          <span class="red-icon">*</span>
          <span>真实离职原因：</span>
          <p>{{ formInfoData.description }}</p>
        </div> -->
        <!-- <div class="form-desc form-desc1" v-if="formInfoData.outProcedure">
          <span class="red-icon">*</span>
          <span>其他离职原因：</span>
          <p>{{ formInfoData.outProcedure }}</p>
        </div> -->
        <div class="form-desc form-desc1" v-if="formInfoData.classState">
          <span class="red-icon">*</span>
          <span>班级交接情况：</span>
          <p>{{ formInfoData.classState }}</p>
          <!-- {{ formInfoData.processVariables }} -->
        </div>
        <div class="form-desc form-desc1" v-if="detailType === 'TEACHER_RESIGN_TAKE_OVER_FOLLOW'">
          <span class="red-icon">*</span>
          <span>新老师首课时间（北京时间）：</span>
          <p>{{ formInfoData.newSchedule }}</p>
          <!-- {{ formInfoData.processVariables }} -->
        </div>
        <ApplicantComponent
          :subDetailType="subDetailType"
          :applicant="{
            name: formInfoData.applyUser || formInfoData.teacherName,
            reles: formInfoData.applyUserRoles,
          }"
        />
      </div>
      <div v-else>
        <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
          <a-form-item label="最后上课时间" :wrapper-col="{ span: 18 }">
            <a-date-picker
              size="large"
              style="width: 100%"
              format="YYYY-MM-DD HH:mm"
              :disabled-date="disabledDate"
              :show-time="{ format: 'HH:mm' }"
              placeholder="请选择离职时间（北京时间）"
              disabled
              v-decorator="[
                'takeOverDate',
                { rules: [{ type: 'object', required: true, message: '请选择交接时间（北京时间）' }] },
              ]"
              @change="onHandoverClass"
            />
          </a-form-item>
          <a-form-item label="交班合规">
            <a-input v-decorator="['reasonable', {}]" placeholder="交班合规" disabled />
          </a-form-item>
          <a-form-item label="离职真实原因">
            <a-select
              size="large"
              v-decorator="['description', { rules: [{ required: true, message: '请选择离职真实原因!' }] }]"
              placeholder="请选择离职真实原因"
            >
              <a-select-option value="全职工作">全职工作</a-select-option>
              <a-select-option value="继续学习">继续学习</a-select-option>
              <a-select-option value="身体原因">身体原因</a-select-option>
              <a-select-option value="家庭原因">家庭原因</a-select-option>
              <a-select-option value="工作不符合预期">工作不符合预期</a-select-option>
              <a-select-option value="培训方面（时间长，无目的性）">培训方面（时间长，无目的性）</a-select-option>
              <a-select-option value="人事相关制度">人事相关制度</a-select-option>
              <a-select-option value="薪酬收入制度">薪酬收入制度</a-select-option>
              <a-select-option value="其他">其他</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="其他真实原因" v-if="form.getFieldValue('description') === '其他'">
            <a-textarea
              v-decorator="['outProcedure', { rules: [{ required: true, message: '请输入离职真实原因!' }] }]"
              placeholder="请输入离职真实原因"
              :auto-size="{ minRows: 3 }"
              @change="oncheckRequired"
            />
          </a-form-item>
          <a-form-item label="班级情况交接">
            <a-textarea
              v-decorator="['classState', { rules: [{ required: true, message: '请输入班级交接情况!' }] }]"
              placeholder="请输入班级情况交接"
              :auto-size="{ minRows: 3 }"
              @change="oncheckRequired"
            />
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="concat-wrap" v-if="formInfoData.relatedTask && detailType !== 'RESIGN_TAKE_OVER_EN'">
      <div class="title">关联任务</div>
      <div class="concat-con">
        <p>任务名称：{{ formInfoData.relatedTask.name }}</p>
      </div>
    </div>
    <RelatedStudents :formInfoData="formInfoData" @onGetStudentId="onGetStudentId" />
    <div class="concat-wrap" v-if="formInfoData.resign">
      <div class="title">关联班级</div>
      <RelatedClass
        class="concat-con"
        v-for="(classItem, index) in formInfoData.relatedClass"
        :classItem="classItem"
        :key="index"
        @onGetStudentId="onGetStudentId"
        :processInstanceId="formInfoData.processInstanceId"
        :adjustScheduleProcess="formInfoData.adjustScheduleProcess"
        :upComingTypeValues="formInfoData.upComingTypeValues"
        :taskId="formInfoData.taskId"
        :subject="formInfoData.subject"
        :showOperate="detailType === 'RESIGN_TAKE_OVER_EN'"
        :startDateTimeFrom="$moment(formInfoData.takeOverDate).format('YYYY-MM-DD HH:mm:ss')"
        :teacherId="formInfoData.teacherId"
        @courseScheduleInfo="courseScheduleInfo"
        changeTeacherReason="老师离职"
      >
      </RelatedClass>
    </div>
  </div>
</template>

<script>
import defaultImg from '../assets/service_pic_head.png';
import RelatedClass from './RelatedClass.vue';
import ApplicantComponent from './ApplicantComponent.vue';
import RelatedStudents from './RelatedStudents.vue';

export default {
  components: { RelatedClass, ApplicantComponent, RelatedStudents },
  props: {
    formInfoData: {
      type: Object,
    },
    subDetailType: {
      // 区分是老师还是班主任，班主任主题色是绿色
      type: String,
      default: 'teacher',
    },
    detailType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      cursor: 'cursor',
      defaultImg,
      isCarousel: false,
      pictures: [],
      visibleClass: true,
      toggleTxt: '收起',
      form: this.$form.createForm(this),
      isRequired: false,
      turnManualReasonTextMap: {
        COURSE_SCHEDULE_CHANGE: '”改约“导致取消排课单',
        COURSE_SCHEDULE_CANCEL: '人工取消课节导致系统的排课单被取消',
        SCHEDULE_ADJUST: '人工调整课节进度后导致系统的排课单被取消',
        TIME_ADJUST: '人工调整上课时间后导致系统的排课单被取消',
        TEACHER_ADJUST: '人工更改授课老师后导致系统的排课单被取消',
        STUDENT_LEAVE: '家长请假后导致系统的排课单被取消',
        SCHEDULE_ADJUST_FAIL: '课节更换老师或课节被取消，导致课节进度调整失败',
        SCHEDULE_ORDER_EXISTS: '老师请假时班级内有发起的排课单',
        DIRECTLY_CANCEL: '直接取消',
        EXIST_48_HOUR_SCHEDULE: '有48小时内开课课节',
        EXIST_2_HOUR_SCHEDULE: '有2小时内开课课节',
        AVAILABLE_TEACHER_NOT_FOUND: '系统未匹配到老师',
        PUBLIC_TURN: '公开课转人工',
        PRIVATE_TURN: '私教课转人工',
      },
    };
  },
  methods: {
    courseScheduleInfo() {
      this.$emit('courseScheduleInfo');
    },
    lookWord() {
      window.open('https://wukongedu.feishu.cn/wiki/wikcno1QonFESjRp08ddR3x3trh?sheet=h2ZIz4', '_blank');
    },
    onGetStudentId(data) {
      this.$emit('onGetStudentId', data);
    },
    // 检查交接合规
    onHandoverClass(value) {
      const times =
        this.$moment(value).format('X') - this.$moment(this.formInfoData.relatedClass[0].beginDateTime).format('X');
      if (times < 90 * 24 * 60 * 60) {
        this.form.getFieldDecorator('reasonable', { initialValue: '否' });
      } else {
        this.form.getFieldDecorator('reasonable', { initialValue: '是' });
      }
      this.oncheckRequired();
    },
    // 检查数据
    oncheckRequired() {
      this.$nextTick(() => {
        const getFieldsValue = this.form.getFieldsValue();
        this.isRequired = Object.entries(getFieldsValue).every((item) => item[1] !== undefined && item[1]);
        // if (this.isRequired) {
        this.$emit('onClassResignation', this.form);
        // }
      });
    },
    // 切换收起详情
    toggleClass() {
      this.visibleClass = !this.visibleClass;
      this.toggleTxt = this.visibleClass ? '收起' : '详情';
    },
    initData(data) {
      if (!this.formInfoData.resign || this.formInfoData.assigneesList[1].data.finished) return;
      // const time = this.$moment(data.takeOverDate);
      // this.form.getFieldDecorator('takeOverDate', { initialValue: time });
      // this.form.getFieldDecorator('outProcedure', { initialValue: data.outProcedure });
      this.form.setFieldsValue({ takeOverDate: this.$moment(data.takeOverDate) });
      this.form.setFieldsValue({ outProcedure: data.outProcedure });
      this.form.setFieldsValue({ description: data.description });
      this.onHandoverClass(data.takeOverDate);
    },
    disabledDate(current) {
      return current && current < this.$moment().subtract(1, 'days');
    },
  },
  mounted() {
    this.initData(this.formInfoData);
  },
};
</script>

<style lang="less" scoped>
.cursor {
  cursor: pointer;
  color: #009cff !important;
}
.classAdmin .cursor {
  cursor: pointer;
  color: #43d186 !important;
}
.title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 20px;
}
.title::before {
  content: '';
  display: block;
  float: left;
  margin: 4px 12px 0 0;
  width: 4px;
  height: 20px;
  background-color: #009cff;
  border-radius: 0px 6px 6px 0px;
}
.task-form {
  border-bottom: 1px solid #eee;
}
.form-name {
  font-size: 20px;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
.form-name span {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 2px 10px;
  border-radius: 6px;
  margin: 0 10px;
}
.pending-status {
  color: #ffbf00;
  background-color: rgba(255, 191, 0, 0.09);
}
.success-status {
  color: #00cf64;
  background-color: rgba(0, 207, 100, 0.09);
}
.refuse-status {
  color: #ff5353;
  background-color: rgba(255, 83, 83, 0.09);
}
.form-teacher {
  font-size: 16px;
  font-weight: 400;
  color: #999;
  line-height: 22px;
  margin-bottom: 15px;
}
.form-teacher .dueDate {
  color: #ff5454;
}
.form-teacher span {
  color: #333;
}
.form-teacher img {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 10px;
}
.form-des {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  margin-bottom: 15px;
}
.form-imgs {
  margin-bottom: 20px;
}
.form-imgs img {
  width: 80px;
  height: 80px;
  border-radius: 4px;
  margin-right: 10px;
}
.courseInfo {
  padding: 20px 0;
}
.course-info {
  padding: 20px 16px;
  background: linear-gradient(180deg, #ecf7ff 0%, #f7fcff 100%);
  border-radius: 20px;
  margin-bottom: 20px;
}
.course-name {
  font-size: 16px;
  font-weight: 600;
  color: #009cff;
  line-height: 22px;
  margin-bottom: 10px;
}
.course-info a:hover {
  text-decoration: underline;
}
.course-type {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 18px;
  margin-top: 10px;
}
.student-item {
  display: flex;
  justify-content: flex-start;
}
.student-item p {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.student-item img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 7px;
}
.student-item .student-name {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  line-height: 22px;
}
.form-desc {
  color: #333;
  font-size: 16px;
}
.form-desc1 {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 6px;
}
.form-desc1 p {
  max-width: 80%;
}
.form-desc2 {
  margin-bottom: 20px;
  padding-left: 8px;
}
.red-icon {
  color: #ff5353;
}
.form-label {
  width: 85px;
}
.form-reason {
  flex: 1;
}
.title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 20px;
}
.title::before {
  content: '';
  display: block;
  float: left;
  margin: 4px 12px 0 0;
  width: 4px;
  height: 20px;
  background-color: #009cff;
  border-radius: 0px 6px 6px 0px;
}
.concat-wrap {
  padding-top: 20px;
  margin-bottom: 20px;
}
.concat-con {
  border: 1px solid #eeeeee;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  padding: 10px;
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
}
.concat-item {
  margin-bottom: 5px;
}
.concat-label {
  color: #666;
  font-size: 13px;
  border-left: 1px solid #dddddd;
  padding: 0 10px;
  display: inline-block;
  &:first-child {
    padding: 0 10px 0 0;
    border-left: 0;
  }
}
.bot-border {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px;
}

.class-item {
  font-size: 14px;
  margin-bottom: 4px;
}
.student-item {
  display: flex;
  justify-content: flex-start;
  margin-right: 20px;
}
.student-item p {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.student-item img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 7px;
}
.student-item .student-name {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  line-height: 22px;
}
.student-wrap {
  display: flex;
  margin-bottom: 22px;
}
.classAdmin {
  .title::before {
    background-color: #43d186;
  }
  .course-name {
    color: #43d186;
  }
}
.explainTC {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('../assets/explain.png') no-repeat center;
  background-size: 100%;
  cursor: pointer;
  margin-right: 5px;
}
.task-explainTitleTC {
  padding-bottom: 5px;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.task-titleTxt {
  font-size: 18px;
  font-weight: 600;
}
.task-explain {
  color: #2eaaf9;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}
</style>
