<template>
  <div :class="subDetailType">
    <div class="task-form">
      <p class="form-name">
        {{ formInfoData.question }}
        <span class="pending-status" v-if="!formInfoData.finished">待处理</span>
        <span class="success-status" v-if="formInfoData.finished && !formInfoData.deleted">已完成</span>
        <span class="refuse-status" v-if="formInfoData.finished && formInfoData.deleted">已拒绝</span>
      </p>
      <div class="form-teacher">
        <img :src="defaultImg" alt="" />
        <span
          >{{ formInfoData.startUserName }}

          <span v-if="formInfoData.userRole"> （ {{ formInfoData.userRole }} ） </span>
        </span>
        提交于
        <span>{{ $moment(formInfoData.startTime).utcOffset(8).format('YYYY年MM月DD日 HH:mm') }}</span>
        | 最迟解决时间
        <span
          v-if="formInfoData.endTime"
          :class="{ dueDate: new Date(formInfoData.endTime).getTime() > new Date(formInfoData.dueDate).getTime() }"
          >{{ $moment(formInfoData.dueDate).format('YYYY年MM月DD日 HH:mm')
          }}{{
            new Date(formInfoData.endTime).getTime() > new Date(formInfoData.dueDate).getTime() ? '(逾期)' : ''
          }}</span
        >
        <span v-else :class="{ dueDate: new Date().getTime() > new Date(formInfoData.dueDate).getTime() }"
          >{{ $moment(formInfoData.dueDate).format('YYYY年MM月DD日 HH:mm')
          }}{{ new Date().getTime() > new Date(formInfoData.dueDate).getTime() ? '(逾期)' : '' }}</span
        >
      </div>
      <div>
        <div class="form-desc form-desc1">
          <!-- <span class="red-icon">*</span> -->
          <span>交接老师：</span>
          <p>{{ formInfoData.teacherName }}</p>
        </div>
        <div class="form-desc form-desc1">
          <!-- <span class="red-icon">*</span> -->
          <span>对外话术：</span>
          <p>老师因个人原因无法继续授课，为保障课堂质量和学习效果，特此交接班级，感谢您的配合。</p>
        </div>
        <div class="form-desc form-desc1">
          <!-- <span class="red-icon">*</span> -->
          <span>最后上课时间（北京）：</span>
          <p>{{ $moment(formInfoData.takeOverDate).format('YYYY年MM月DD日 HH:mm') }}</p>
        </div>
        <div class="form-desc form-desc1">
          <!-- <span class="red-icon">*</span> -->
          <span>交接范围：</span>
          <p>{{ formInfoData.takeOverAll ? '全部交接' : '部分交接' }}</p>
        </div>
        <template v-if="formInfoData.turnManualReason">
          <div class="form-desc form-desc1">
            <!-- <span class="red-icon">*</span> -->
            <span>转人工原因</span>
          </div>
          <div class="form-desc form-desc2">
            {{ turnManualReasonTextMap[formInfoData.turnManualReason] }}
          </div>
        </template>
      </div>
    </div>

    <div class="concat-wrap">
      <div class="title">关联班级</div>
      <RelatedClass
        class="concat-con"
        v-for="(classItem, index) in formInfoData.relatedClass"
        :classItem="classItem"
        :key="index"
        @onGetStudentId="onGetStudentId"
        :upComingTypeValues="formInfoData.upComingTypeValues"
        :processInstanceId="formInfoData.processInstanceId"
        :adjustScheduleProcess="formInfoData.adjustScheduleProcess"
        :taskId="formInfoData.taskId"
        :showOperate="formInfoData.subject === 'MATH' || formInfoData.subject === 'ENGLISH'"
        :subject="formInfoData.subject"
        :teacherId="formInfoData.teacherId"
        @courseScheduleInfo="courseScheduleInfo"
        :startDateTimeFrom="$moment(formInfoData.takeOverDate).format('YYYY-MM-DD HH:mm:ss')"
        :changeTeacherReason="formInfoData.takeOverAll ? '老师离职' : '老师交接班'"
      >
      </RelatedClass>
    </div>
  </div>
</template>

<script>
import defaultImg from '../assets/service_pic_head.png';
import RelatedClass from './RelatedClass.vue';

export default {
  props: {
    formInfoData: {
      type: Object,
    },
    subDetailType: {
      // 区分是老师还是班主任，班主任主题色是绿色
      type: String,
      default: 'teacher',
    },
    taskType: {
      type: String,
    },
  },
  components: { RelatedClass },
  data() {
    return {
      cursor: 'cursor',
      defaultImg,
      isCarousel: false,
      pictures: [],
      turnManualReasonTextMap: {
        COURSE_SCHEDULE_CHANGE: '”改约“导致取消排课单',
        COURSE_SCHEDULE_CANCEL: '人工取消课节导致系统的排课单被取消',
        SCHEDULE_ADJUST: '人工调整课节进度后导致系统的排课单被取消',
        TIME_ADJUST: '人工调整上课时间后导致系统的排课单被取消',
        TEACHER_ADJUST: '人工更改授课老师后导致系统的排课单被取消',
        STUDENT_LEAVE: '家长请假后导致系统的排课单被取消',
        SCHEDULE_ADJUST_FAIL: '课节更换老师或课节被取消，导致课节进度调整失败',
        SCHEDULE_ORDER_EXISTS: '老师请假时班级内有发起的排课单',
        DIRECTLY_CANCEL: '直接取消',
        EXIST_48_HOUR_SCHEDULE: '有48小时内开课课节',
        EXIST_2_HOUR_SCHEDULE: '有2小时内开课课节',
        AVAILABLE_TEACHER_NOT_FOUND: '系统未匹配到老师',
        PUBLIC_TURN: '公开课转人工',
        PRIVATE_TURN: '私教课转人工',
      },
    };
  },
  methods: {
    courseScheduleInfo() {
      this.$emit('courseScheduleInfo');
    },
    lookWord() {
      if (this.taskType === 'TASK_RESIGN_STUDENT_OLD_CLASSADMIN') {
        window.open('https://wukongedu.feishu.cn/wiki/wikcno1QonFESjRp08ddR3x3trh', '_blank');
      } else if (this.taskType === 'TASK_RESIGN_STUDENT_NEW_CLASSADMIN') {
        window.open('https://wukongedu.feishu.cn/wiki/wikcno1QonFESjRp08ddR3x3trh', '_blank');
      }
    },
    onGetStudentId(data) {
      this.$emit('onGetStudentId', data);
    },
    lookStudentInfo() {
      this.$emit('lookStudentInfo');
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.cursor {
  cursor: pointer;
  color: #009cff !important;
}
.classAdmin .cursor {
  cursor: pointer;
  color: #43d186 !important;
}
.title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 20px;
}
.title::before {
  content: '';
  display: block;
  float: left;
  margin: 4px 12px 0 0;
  width: 4px;
  height: 20px;
  background-color: #009cff;
  border-radius: 0px 6px 6px 0px;
}
.task-form {
  border-bottom: 1px solid #eee;
}
.form-name {
  font-size: 20px;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 15px;
}
.form-name span {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 2px 10px;
  border-radius: 6px;
  margin: 0 10px;
}
.pending-status {
  color: #ffbf00;
  background-color: rgba(255, 191, 0, 0.09);
}
.success-status {
  color: #00cf64;
  background-color: rgba(0, 207, 100, 0.09);
}
.refuse-status {
  color: #ff5353;
  background-color: rgba(255, 83, 83, 0.09);
}
.form-teacher {
  font-size: 16px;
  font-weight: 400;
  color: #999;
  line-height: 22px;
  margin-bottom: 15px;
}
.form-teacher .dueDate {
  color: #ff5454;
}
.form-teacher span {
  color: #333;
}
.form-teacher img {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 10px;
}
.form-des {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  margin-bottom: 15px;
}
.form-imgs {
  margin-bottom: 20px;
}
.form-imgs img {
  width: 80px;
  height: 80px;
  border-radius: 4px;
  margin-right: 10px;
}
.courseInfo {
  padding: 20px 0;
}
.course-info {
  padding: 20px 16px;
  background: linear-gradient(180deg, #ecf7ff 0%, #f7fcff 100%);
  border-radius: 20px;
  margin-bottom: 20px;
}
.course-name {
  font-size: 16px;
  font-weight: 600;
  color: #009cff;
  line-height: 22px;
  margin-bottom: 10px;
}
.course-info a:hover {
  text-decoration: underline;
}
.course-type {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 18px;
  margin-top: 10px;
}
.student-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.student-item p {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.student-item img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 7px;
}
.student-item .student-name {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  line-height: 22px;
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.form-desc {
  color: #333;
  font-size: 16px;
}
.form-desc1 {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 6px;
}
.form-desc1 p {
  max-width: 80%;
}
.form-desc2 {
  margin-bottom: 20px;
  padding-left: 8px;
}
.red-icon {
  color: #ff5353;
}
.form-label {
  width: 85px;
}
.form-reason {
  flex: 1;
}
.title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 20px;
}
.title::before {
  content: '';
  display: block;
  float: left;
  margin: 4px 12px 0 0;
  width: 4px;
  height: 20px;
  background-color: #009cff;
  border-radius: 0px 6px 6px 0px;
}
.concat-wrap {
  padding-top: 20px;
  margin-bottom: 20px;
}
.concat-con {
  border: 1px solid #eeeeee;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  padding: 10px;
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
}
.concat-item {
  margin-bottom: 5px;
}
.concat-label {
  color: #666;
  font-size: 13px;
  border-left: 1px solid #dddddd;
  padding: 0 10px;
  display: inline-block;
  &:first-child {
    padding: 0 10px 0 0;
    border-left: 0;
  }
}
.bot-border {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px;
}

.class-item {
  font-size: 14px;
  margin-bottom: 4px;
}
.student-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.student-item p {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.student-item img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 7px;
}
.student-item .student-name {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  line-height: 22px;
}
.student-wrap {
  display: flex;
  margin-bottom: 22px;
}
.classAdmin {
  .title::before {
    background-color: #43d186;
  }
  .course-name {
    color: #43d186;
  }
}
.explainTC {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('../assets/explain.png') no-repeat center;
  background-size: 100%;
  cursor: pointer;
  margin-right: 5px;
}
.task-explainTitleTC {
  padding-bottom: 5px;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.task-titleTxt {
  font-size: 18px;
  font-weight: 600;
}
.task-explain {
  color: #2eaaf9;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}
.form-name {
  font-size: 20px;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
</style>
