<template>
  <div>
    <div class="task-form">
      <p class="form-name">
        <a-tooltip :overlayStyle="{ maxWidth: '500px' }">
          <template slot="title">
            <div class="task-explainTitleTC">
              <span class="task-explain" @click="lookWord">查看文档说明</span>
            </div>
            <div>
              【完成方式】电话<br />
              【完成时效】3天<br />
              【操作步骤】<br />
              1、沟通前： <br />
              a.查看学员花果档案、学情及跟进记录（上课时间及课频、级别进度、遗留问题等）<br />
              b.排课核实：检查学员当前排课情况，若待开课不足10节需及时加课<br />
              c.看课：了解学员上课情况、课堂表现等<br />
              2、沟通中： <br />
              a.身份介绍、核实学情：姓名、上课时间及频次、班型和级别进度、授课老师、课时扣减规则等<br />
              b.确认是否有遗留问题未解决<br />
              c.学情反馈、了解家长期望并做学习规划（不强制要求）<br />
              3、沟通后： <br />
              a.及时核对当前排课情况，有误的及时更改调整<br />
              b.若有遗留问题/前班主任失误点，需及时向领导汇报<br />
              c.及时填写跟进记录，记录沟通的重要信息<br />

              【注意事项】 ③跟进记录中的每一项均需和家长沟通确认才可完成任务，不得在未确认沟通的情况下点击完成任务
            </div>
          </template>
          <i class="explainTC"></i>
        </a-tooltip>
        {{ formInfoData.question }}
        <span class="pending-status" v-if="!formInfoData.finished">待处理</span>
        <span class="success-status" v-if="formInfoData.finished && !formInfoData.deleted">已完成</span>
        <span class="refuse-status" v-if="formInfoData.finished && formInfoData.deleted">已拒绝</span>
      </p>
      <div class="form-teacher">
        <img :src="defaultImg" alt="" />
        <span
          >{{ formInfoData.startUserName ? formInfoData.startUserName : '系统'
          }}<span v-if="formInfoData.userRole">（ {{ formInfoData.userRole }} ）</span></span
        >
        提交于
        <span>{{ $moment(formInfoData.startTime).utcOffset(8).format('YYYY年MM月DD日 HH:mm') }}</span>
        | 最迟解决时间

        <span :class="formInfoData.expireFlag ? 'dueDate' : ''">{{ formInfoData.dueDate }}</span>
      </div>
    </div>
    <RelatedStudents :formInfoData="formInfoData" @lookStudentInfo="lookStudentInfo" detailMode="drawer" />
    <div style="padding-top: 15px; border-top: 1px solid #e9e9e9" v-if="formInfoData.preClassAdmin">
      <div class="title">
        <span>前班主任</span>
      </div>
      <div class="student-wrap">
        <div class="student-item" style="padding-bottom: 20px">
          <img :src="defaultImg" alt="" class="handler-img" />
          <div>
            <p class="student-name">
              {{ formInfoData.preClassAdmin.preClassAdminName }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import defaultImg from '../assets/service_pic_head.png';
import RelatedStudents from './RelatedStudents.vue';

export default {
  props: {
    formInfoData: {
      type: Object,
    },
  },
  components: { RelatedStudents },
  data() {
    return {
      defaultImg,
    };
  },
  methods: {
    lookWord() {
      window.open('https://wukongedu.feishu.cn/wiki/wikcnsX8686SlkufJKDlkjYNWhh', '_blank');
    },
    lookStudentInfo() {
      this.$emit('lookStudentInfo');
    },
    mounted() {
      console.log(this.formInfoData);
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 20px;
}
.title::before {
  content: '';
  display: block;
  float: left;
  margin: 4px 12px 0 0;
  width: 4px;
  height: 20px;
  background-color: #43d186;
  border-radius: 0px 6px 6px 0px;
}
.task-form {
  border-bottom: 1px solid #eee;
}

.form-name span {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 2px 10px;
  border-radius: 6px;
  margin: 0 10px;
}
.pending-status {
  color: #ffbf00;
  background-color: rgba(255, 191, 0, 0.09);
}
.success-status {
  color: #00cf64;
  background-color: rgba(0, 207, 100, 0.09);
}
.refuse-status {
  color: #ff5353;
  background-color: rgba(255, 83, 83, 0.09);
}
.form-teacher {
  font-size: 16px;
  font-weight: 400;
  color: #999;
  line-height: 22px;
  margin-bottom: 15px;
}
.form-teacher .dueDate {
  color: #ff5454;
}
.form-teacher span {
  color: #333;
}
.form-teacher img {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 10px;
}

.student-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.student-item p {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.student-item img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 7px;
}
.student-item .student-name {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  line-height: 22px;
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.student-wrap {
  display: flex;
  margin-bottom: 22px;
}

.explainTC {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('../assets/explain.png') no-repeat center;
  background-size: 100%;
  cursor: pointer;
  margin-right: 5px;
}
.task-explainTitleTC {
  padding-bottom: 5px;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.task-explain {
  color: #2eaaf9;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}
.form-name {
  font-size: 20px;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
</style>
